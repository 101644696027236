import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { FormattedMessage } from 'react-intl';
import { Gtm } from '../../../../utils/gtm';

import messages from '../../../../i18n/base-en.js';

import OfferPayoutInfo from '../OfferPayoutInfo/OfferPayoutInfo.react';
import OfferRestrictions from '../OfferRestrictions/OfferRestrictions.react';
import OffersImage from '../../../Offers/OffersImage.react';
import OfferStatusBar from '../../OfferHeader/OfferStatusBar/OfferStatusBar.react';
import OfferTrackingLinkLandingPages from '../../OfferTrackingLinkLandingPages/OfferTrackingLinkLandingPages.react';
import { Container } from '../../../reusables';

import { explodeDescription } from '../../../../utils/helpers';
import ShareIcon from '../../../reusables/svg/ShareIcon';
import { openModal } from '../../../Modal/modal-actions/modal-actions';

const OfferInfo = ({
  approvalStatus,
  fields,
  goals,
  isAutoOptimized,
  offer,
  offerIsFetching,
  values,
  openShareModal,
  isReferrals,
  cancelModalButton,
  isLandingPageModalOpen,
  isMoreInfoIconVisible,
  dispatch,
}) => {
  const getActiveLandingPages = (landingPages) => {
    if (landingPages) {
      return landingPages.filter((landingPage) => landingPage.status === 'active');
    }
  };

  const explodedDescription = explodeDescription(offer.description);
  const goal = goals.find((currentGoal) => currentGoal.id === '0');
  const payout = {
    defaultPayout: goal ? goal.default_payout : offer.default_payout,
    percentPayout: goal ? goal.percent_payout : offer.percent_payout,
    payoutType: goal ? goal.payout_type : offer.payout_type,
  };

  const openDescriptionModal = () => {
    dispatch(
      openModal({
        name: 'OfferDescriptionModal',
        modalProps: {
          dispatch,
          description: explodedDescription.description,
          className: 'modal-description',
        },
      })
    );
  };

  return (
    <Container className="row z-depth-2" id="offer-info">
      <div id="offer-global-info">
        <div className="offer-title">
          <div className="offer-img__star">
            <OffersImage
              alt={offer.name}
              borderRadius="15px"
              fontSize="13px"
              height="100px"
              thumbnail={offer.thumbnail}
              width="100px"
            />
            {offer.featured ? (
              <div className="featured_container">
                <img alt="Featured" className="featured" src="/img/featured-star.svg" />
              </div>
            ) : null}
          </div>
          <div className="header-actions">
            <h1 className="offer-name">{offer.name}</h1>
            <span
              className="share-icon"
              onClick={() => {
                openShareModal();
                Gtm.event('offers', 'Click', 'share icon');
              }}
              title="Share this offer"
            >
              <ShareIcon />
            </span>
          </div>
        </div>
        <div className="offer-info">
          <div className="label">
            <FormattedMessage {...messages.offerId} />
          </div>
          <div>{offer.id}</div>
        </div>
        {approvalStatus ? (
          <div className="offer-info status">
            <div className="label">
              <FormattedMessage {...messages.offerStatus.label} />
            </div>
            <OfferStatusBar approvalStatus={approvalStatus} dispatch={dispatch} isButtonStyled={false} />
          </div>
        ) : null}
        <div className="offer-info">
          <div className="label">
            <FormattedMessage {...messages.offerDescription} />
          </div>
          <div id="offer-description" onClick={openDescriptionModal}>
            <HTMLEllipsis basedOn="words" maxLine="2" unsafeHTML={explodedDescription.description} />
            <div className="hyperlink" id="btn-offer-description">
              <FormattedMessage {...messages.genericTextReadMore} />
            </div>
          </div>
        </div>
        <OfferPayoutInfo
          dispatch={dispatch}
          goals={goals}
          isMoreInfoIconVisible={isMoreInfoIconVisible}
          isReferrals={isReferrals}
          offerAcceptedCountries={offer.countries}
          offerConversionCap={offer.conversion_cap}
          offerDefaultPayout={offerIsFetching ? '' : payout.defaultPayout}
          offerEPC={offer.epc_affiliation}
          offerIsFetching={offerIsFetching}
          offerPayoutType={offerIsFetching ? '' : payout.payoutType}
          offerPayoutTypes={offer.conversion_types ? offer.conversion_types.map((ct) => ct.name) : []}
          offerPercentPayout={payout.percentPayout}
          offerTopCountries={offer.top_countries}
          tiersTags={offerIsFetching ? [] : explodedDescription.tiersTags}
          tiersTitleTagContainer={offerIsFetching ? [] : explodedDescription.tiersTitleTagContainer}
        />
        {offer.restrictions && offer.restrictions.length > 0 ? (
          <OfferRestrictions offerRestrictions={offer.restrictions} />
        ) : null}
      </div>
      <div id="landing-page-wrapper">
        <OfferTrackingLinkLandingPages
          cancelModalButton={cancelModalButton}
          fields={fields}
          isAutoOptimized={isAutoOptimized}
          isLandingPageModalOpen={isLandingPageModalOpen}
          isReferrals={isReferrals}
          offerPreviewUrl={offer.preview_url}
          offerScreenshots={offer.screenshot}
          offerUrl={getActiveLandingPages(offer.landing_pages)}
          performerLandingIds={explodedDescription.config?.performer_landing_ids}
          trendingPerformers={explodedDescription.config?.trending_performers}
          values={values}
        />
      </div>
    </Container>
  );
};

OfferInfo.propTypes = {
  approvalStatus: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  goals: PropTypes.array.isRequired,
  isAutoOptimized: PropTypes.bool.isRequired,
  offer: PropTypes.object.isRequired,
  offerIsFetching: PropTypes.bool.isRequired,
  openShareModal: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default OfferInfo;
