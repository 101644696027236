import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';
import { change, getValues } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import messages from '../i18n/base-en.js';

import OffersCards from '../components/Offers/OffersCards.react';
import OffersTable from '../components/Offers/OffersTable.react';
import { Container, NewsBox, Pagination, SwiperSlider, ViewMode } from '../components/reusables';
import NewProductLabel from '../components/reusables/svg/NewProductLabel.react';
import Star from '../components/reusables/svg/Star.react';

import {
  changeOffersListPage,
  changeOffersRowsPerPage,
  changeOffersLocationQuery,
  fetchOffers,
  setOffersSearchFilters,
} from '../components/Offers/actions/offers-actions';
import { fetchRecentOffers, changeTopOffersPage, fetchTopOffers } from '../components/Offers/actions/topoffers-actions';

import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';

import { Gtm } from '../utils/gtm';
import OffersSearch from '../components/Offers/OffersSearch.js';
import { OFFER_SEARCH_FORM_NAME, offerDropdownFields } from '../components/reusables/offersSearch/values.js';
import OffersSliderLarge from '../components/Offers/slider/OffersSliderLarge.react.js';
import { useCallback } from 'react';
import _ from 'lodash';
import SelectCustom from '../components/reusables/form/SelectCustom';
const Offers = ({
  allOffers,
  categoriesList,
  countries,
  dispatch,
  listChannels,
  listConversionTypes,
  listMediaPlacements,
  listVerticals,
  listCountries,
  location: { query },
  news,
  offers,
  offersInfos,
  offersIsFetching,
  offersSearch,
  recentOffers,
  search,
  skinConfigurations,
  topOffers,
  offersSlides,
}) => {
  const timeoutRef = useRef();
  const [viewMode, setViewMode] = useState('table');
  const [windowWidthSize, setWindowWidthSize] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState('all');
  const [offersSlidesByVertical, setOffersSlidesByVertical] = useState(offersSlides);
  const [sortValue, setSortValue] = useState('{"featured": "desc"}');
  let [level1Active, setLevel1Active] = useState(true);

  offers = offers.filter((offer) => offer.id !== process.env.REACT_APP_VAST_PREROLL_OFFER_ID);

  const offerByVerticalLookup = allOffers.reduce((acc, offer) => {
    offer.verticals.forEach((vertical) => {
      if (!acc[vertical.id]) {
        acc[vertical.id] = [];
      }
      acc[vertical.id].push(offer.id);
    });
    return acc;
  }, {});

  function getAvailableVerticals() {
    return listVerticals.filter((vertical) => offerByVerticalLookup[vertical.id]);
  }

  const othersSearch = ['approval_status'];
  const statusMapping = {
    approved: 'Approved',
    null: 'Approval Required',
    pending: 'Pending',
    rejected: 'Rejected',
  };

  useEffect(() => {
    if (!offersIsFetching && !_.isEmpty(query) && !query.vertical) {
      timeoutRef.current = setTimeout(() => {
        const offersList = document.getElementById('offers-list');
        if (!offersList) {
          return;
        }
        offersList.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, 100);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [offersIsFetching]);

  useEffect(() => {
    dispatch(setOffersSearchFilters(search));
    SkinConfigurationsUtils.manageAccess(browserHistory, skinConfigurations, 'offers');
    function handleResize() {
      setWindowWidthSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (selectedTab === 'all') {
      dispatch(fetchOffers(search));
    }
  }, [search]);

  const getOffersItemsIsOpen = (skin) => {
    const SKIN_COMPONENT_NAME = 'Offers';
    return {
      offersSearch: !skin.isItemMinify(SKIN_COMPONENT_NAME, 'OffersSearch'),
      offersList: !skin.isItemMinify(SKIN_COMPONENT_NAME, 'offers-list'),
    };
  };

  const getOffersItemsVisibility = (skin) => {
    const SKIN_COMPONENT_NAME = 'Offers';
    return {
      itemVideoIcon: skin.isItemVisible(SKIN_COMPONENT_NAME, 'title.VideoModalIcon'),
      newsBox: skin.isItemVisible('Global', 'NewsBox'),
      offersSearch: skin.isItemVisible(SKIN_COMPONENT_NAME, 'OffersSearch'),
      offersList: skin.isItemVisible(SKIN_COMPONENT_NAME, 'offers-list'),
      recentOffers: skin.isItemVisible(SKIN_COMPONENT_NAME, 'recent-offers.FeaturedOffersList'),
      swiperSlider: skin.isItemVisible(SKIN_COMPONENT_NAME, 'SwiperSlider'),
      title: skin.isItemVisible(SKIN_COMPONENT_NAME, 'title'),
      topOffers: skin.isItemVisible(SKIN_COMPONENT_NAME, 'top-offers.FeaturedOffersList'),
      epcIcon: skin.isItemVisible(SKIN_COMPONENT_NAME, 'epcIcon'),
    };
  };

  const buildFilters = ({ updateFilters, offersSearch, search }) => {
    const categories = {};
    const filters = {};
    const others = {};
    const offersSearchValues = getValues(offersSearch) || {};

    for (const key in updateFilters) {
      const value = updateFilters[key];
      offersSearchValues[key] = value;
    }

    let featured = false;
    let searchString = '';

    Object.keys(offersSearchValues).map((key) => {
      if (key === 'searchString') {
        searchString = offersSearchValues[key];
      } else if (key === 'featured') {
        featured = offersSearchValues[key];
      } else if (othersSearch.indexOf(key) >= 0) {
        others[key] = offersSearchValues[key];
      } else if (Object.keys(search.categories).indexOf(key) <= -1) {
        filters[key] = offersSearchValues[key];
      } else {
        categories[key] = offersSearchValues[key];
      }
    });

    return {
      categories,
      featured,
      filters,
      others,
      searchString,
    };
  };

  const handlePageChange = (page) => {
    dispatch(changeOffersListPage(page));
  };

  const handleSearchSubmit = (props) => {
    const { updateFilters } = props || {};

    const newFilters = buildFilters({ updateFilters, offersSearch, search });

    dispatch(changeOffersListPage(1));
    dispatch(setOffersSearchFilters({ ...search, ...newFilters }));
  };

  const handleSort = (sortObject) => {
    const categoriesSort = {};
    const othersSort = {};
    const sort = {};

    const name = Object.keys(sortObject);
    const direction = Object.values(sortObject)[0];
    if (Object.keys(search.categories).indexOf(name) >= 0) {
      categoriesSort[name] = direction;
    } else {
      sort[name] = direction;
    }

    if (selectedTab === 'recent') {
      dispatch(
        fetchRecentOffers({
          sort,
          othersSort,
        })
      );
    } else if (selectedTab === 'top') {
      dispatch(
        fetchTopOffers({
          sort,
          othersSort,
        })
      );
    } else {
      dispatch(
        setOffersSearchFilters({
          ...search,
          sort,
          categoriesSort,
          othersSort,
        })
      );
    }
    setSortValue(sortObject);
  };

  const handleTopPageChange = (newPage) => {
    dispatch(changeTopOffersPage(newPage));
  };

  const handleRecentPageChange = (newPage) => {
    dispatch(fetchRecentOffers({ ...recentOffers.search, page: newPage }));
  };

  const handleRowsChange = (value, page) => {
    dispatch(changeOffersRowsPerPage(value, page));
  };

  const handleViewModeChange = (viewMode) => {
    setViewMode(viewMode);
  };

  const selectTab = (tab) => {
    Gtm.event('offers', 'Click', `${tab} offers`);
    setSelectedTab(tab);
  };

  const SKIN = new SkinConfigurationsUtils(skinConfigurations);
  const IS_VISIBLE = getOffersItemsVisibility(SKIN);
  const IS_OPEN = getOffersItemsIsOpen(SKIN);

  const searchLimit = parseInt(search.limit, 10);
  const getPaginationComponent = (selectId, isMobileHidden) => (
    <Pagination
      choices={[25, 50, 100, 200]}
      className="pull-right"
      currentPage={offersInfos.currentPage}
      handlePageChange={handlePageChange}
      handleRowsChange={handleRowsChange}
      pageCount={offersInfos.pageCount}
      rowsPerPage={searchLimit}
      selectId={selectId}
      totalResults={offersInfos.total}
    />
  );

  if (window && window.onresize) {
    window.onresize(true);
  }

  if (windowWidthSize <= 1240 && viewMode !== 'cards') {
    setViewMode('cards');
  }

  useEffect(() => {
    const queryVerticals = query.vertical ? (query.vertical.includes(',') ? query.vertical.split(',') : [query.vertical]) : [];
    const queryIds = query.id ? (query.id.includes(',') ? query.id.split(',') : [query.id]) : [];
    if (query?.vertical?.length > 0) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
            Vertical: queryVerticals,
          }),
        },
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.vertical, queryVerticals));
    }

    if (query?.id?.length > 0) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
            Id: queryIds,
          }),
        },
        searchString: search.searchString,
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.id, queryIds));
    }

    if (query.search) {
      dispatch(changeOffersLocationQuery(query));
      search = {
        ...search,
        filters: {
          ...(search.filters = {
            ...search.filters,
          }),
        },
        searchString: query.search,
      };
      dispatch(change(OFFER_SEARCH_FORM_NAME, offerDropdownFields.id, queryIds));
    }

    dispatch(fetchOffers(search));
  }, [query.vertical, query.id]);

  useEffect(() => {
    const verticals = [...search.filters.Vertical];
    const slides = [];
    offersSlides.map((slide) => {
      if (verticals.some((vertical) => vertical === slide.vertical.value)) {
        slides.push(slide);
        setOffersSlidesByVertical(slides);
      }
    });

    let level1Active = true;

    if (!slides.length && verticals?.length) {
      level1Active = false;
    }

    setLevel1Active(level1Active);

    if (slides.length < 6) {
      offersSlides.map((slide) => {
        if (!slides.includes(slide)) {
          slides.push(slide);
          setOffersSlidesByVertical(slides);
        }
      });
    }
  }, [search.filters.Vertical, offersSlides, setLevel1Active]);

  const verticalsAsString = Array.isArray(search.filters.Vertical.length)
    ? search.filters.Vertical.join(',')
    : search.filters.Vertical;

  const sliderGtmEvent = useCallback(
    ({ positionIndex, level, imgSrc, arrowDirection }) => {
      const position = positionIndex + 1;

      let vertical = listVerticals?.find((vertical) => vertical.id === verticalsAsString)?.name || 'all';
      vertical = vertical.toLowerCase();

      const eventParameterValue = arrowDirection || `${vertical}_${position}_${imgSrc}`;

      const eventNamePrefix = arrowDirection ? 'offers_carrousel_arrows' : 'offers_carrousel';
      const eventName = `${eventNamePrefix}_${level}`;

      Gtm.newEvent(eventName, false, 'click', 'string', eventParameterValue);
    },
    [listVerticals, verticalsAsString]
  );

  let level1 = offersSlidesByVertical?.filter(({ level_one }) => level_one);
  const level2 = offersSlidesByVertical?.filter(({ level_one }) => !level_one);

  if (!verticalsAsString?.length) {
    level1 = level1?.filter(({ vertical }) => vertical?.label?.toLowerCase() === 'all');
    level1Active = level1?.length ? true : false;
  }

  if (offersSlidesByVertical.length < 1) {
    return null;
  }

  return (
    <div className="container-fluid app-container" id="offers">
      {news.active && IS_VISIBLE.newsBox ? (
        <div className="row">
          <Container className="col-xs-12 col-md-12 col-lg-12 shorter-xlg-col-12 z-depth-2 no-margin-top" id="newsbox">
            <NewsBox content={news} />
          </Container>
        </div>
      ) : null}

      {IS_VISIBLE.swiperSlider ? (
        <>
          <OffersSliderLarge
            dispatch={dispatch}
            level1={level1}
            level1Active={level1Active}
            sliderGtmEvent={sliderGtmEvent}
            verticalsAsString={verticalsAsString}
          />
          <div className="row offers-slider">
            <div className="offer-slider-title subtitle">
              <h2 className="primary-color">Recommended Offers</h2>
              <div className="offer-slider-arrows">
                <div
                  className="prev swiper-prev"
                  onClick={() => {
                    sliderGtmEvent({ level: 2, arrowDirection: 'left' });
                  }}
                >
                  <img src="/img/offers/arrow-left.svg" />
                </div>
                <div
                  className="next swiper-next"
                  onClick={() => {
                    sliderGtmEvent({ level: 2, arrowDirection: 'right' });
                  }}
                >
                  <img src="/img/offers/arrow-right.svg" />
                </div>
              </div>
            </div>
            <SwiperSlider level1Active={level1Active} verticalsAsString={verticalsAsString}>
              {typeof level2 !== 'undefined'
                ? level2?.map((slide, index) => (
                    <div className="slide" key={`slide-${index}`}>
                      <div className="no-click" />
                      {slide.cta_link && !slide.cta_text ? (
                        <Link
                          className="btn wrap-image"
                          onClick={() => {
                            sliderGtmEvent({ level: 2, positionIndex: index, imgSrc: slide.image_url });
                          }}
                          onlyActiveOnIndex={false}
                          to={() => {
                            const url = new URL(slide.cta_link);
                            return url.search ? url.pathname + url.search : url.pathname;
                          }}
                        >
                          <img alt={slide.cta_text} src={slide.image_url} />
                        </Link>
                      ) : null}
                      {(slide.cta_link && slide.cta_text) || (!slide.cta_link && !slide.cta_text) ? (
                        <img alt={slide.cta_text} className="has-placeholder" src={slide.image_url} />
                      ) : null}
                      {slide.cta_text && slide.cta_link ? (
                        <Link
                          className="btn"
                          onClick={() => {
                            Gtm.event('offers slide', 'Click', `offer carrousel - ${slide.image_url}`);
                          }}
                          onlyActiveOnIndex={false}
                          to={() => {
                            const url = new URL(slide.cta_link);
                            return url.search ? url.pathname + url.search : url.pathname;
                          }}
                        >
                          {slide.cta_text}
                        </Link>
                      ) : null}
                    </div>
                  ))
                : offersSlidesByVertical?.map((slide, index) => (
                    <div className="placeholder" key={`slide-${index}`}>
                      <div className="slide-placeholder" />
                    </div>
                  ))}
            </SwiperSlider>
          </div>
        </>
      ) : null}

      <div className="offer-tabs">
        <div className={`menuTab ${selectedTab === 'all' ? 'selected' : ''}`} onClick={() => selectTab('all')}>
          All Offers
        </div>
        <div className={`menuTab ${selectedTab === 'top' ? 'selected' : ''}`} onClick={() => selectTab('top')}>
          Top Offers
        </div>
        <div className={`menuTab ${selectedTab === 'recent' ? 'selected' : ''}`} onClick={() => selectTab('recent')}>
          Recently Added Offers
        </div>
      </div>

      {IS_VISIBLE.topOffers && selectedTab === 'top' ? (
        <div className="row">
          <Container
            Svg={Star}
            className="featured-container div-container col-xs-12 col-sm-12 col-md-12 shorter-md-col-12 shorter-sm-col-12 z-depth-2 black-title svg-golden"
            isOpen
            loading={topOffers.offersIsFetching}
            title={<FormattedMessage {...messages.homeFeaturedOffers} />}
          >
            <div className="offers-list-header">
              <ViewMode
                classNames={['pull-left', 'hidden-xs', 'hidden-sm', 'viewmode']}
                currentViewMode={viewMode}
                handleViewModeChange={handleViewModeChange}
              />
              <div className="sort customSelects">
                <div className="sort-by fields" data-cy="sort-by-dropdown">
                  <SelectCustom
                    id="sorting"
                    label={messages.genericTextSortingLandingPage.description}
                    styleBox={true}
                    onChange={(e) => {
                      handleSort(JSON.parse(e));
                    }}
                    options={[
                      {
                        value: '{"featured": "desc"}',
                        text: 'Best Match',
                      },
                      {
                        value: '{"id": "desc"}',
                        text: 'Newest',
                      },
                      {
                        value: '{"default_payout": "desc"}',
                        text: 'Highest Payout',
                      },
                      {
                        value: '{"default_payout": "asc"}',
                        text: 'Lowest Payout',
                      },
                      {
                        value: '{"epc_affiliation": "desc"}',
                        text: 'Highest EPC',
                      },
                      {
                        value: '{"epc_affiliation": "asc"}',
                        text: 'Lowest EPC',
                      },
                    ]}
                    showSelectedFieldCheckmark={false}
                    value={sortValue}
                  />
                </div>
              </div>
              <Pagination
                className="pull-right"
                currentPage={topOffers.offersInfos.currentPage}
                handlePageChange={handleTopPageChange}
                pageCount={topOffers.offersInfos.pageCount}
                rowsPerPage={topOffers.offers.length}
                totalResults={topOffers.offersInfos.total}
              />
            </div>
            {viewMode === 'cards' ? (
              <OffersCards
                epcIconIsVisible={IS_VISIBLE.epcIcon}
                offers={topOffers.offers}
                statusMapping={statusMapping}
                widthSize={windowWidthSize}
              />
            ) : (
              <OffersTable
                epcIconIsVisible={IS_VISIBLE.epcIcon}
                offers={topOffers.offers}
                search={topOffers.search}
                statusMapping={statusMapping}
              />
            )}
            <Pagination
              className="pull-right"
              currentPage={topOffers.offersInfos.currentPage}
              handlePageChange={handleTopPageChange}
              pageCount={topOffers.offersInfos.pageCount}
              rowsPerPage={topOffers.offers.length}
              totalResults={topOffers.offersInfos.total}
            />
          </Container>
        </div>
      ) : null}
      {IS_VISIBLE.recentOffers && selectedTab === 'recent' ? (
        <div className="row">
          <Container
            Svg={NewProductLabel}
            className="featured-container div-container col-xs-12 col-sm-12 col-md-12 shorter-md-col-12 shorter-sm-col-12 z-depth-2 black-title"
            isOpen
            loading={recentOffers.offersIsFetching}
            title={<FormattedMessage {...messages.offerRecentlyAdded} />}
          >
            <div className="offers-list-header">
              <ViewMode
                classNames={['pull-left', 'hidden-xs', 'hidden-sm', 'viewmode']}
                currentViewMode={viewMode}
                handleViewModeChange={handleViewModeChange}
              />
              <div className="sort customSelects">
                <div className="sort-by fields" data-cy="sort-by-dropdown">
                  <SelectCustom
                    id="sorting"
                    label={messages.genericTextSortingLandingPage.description}
                    styleBox={true}
                    onChange={(e) => {
                      handleSort(JSON.parse(e));
                    }}
                    options={[
                      {
                        value: '{"featured": "desc"}',
                        text: 'Best Match',
                      },
                      {
                        value: '{"id": "desc"}',
                        text: 'Newest',
                      },
                      {
                        value: '{"default_payout": "desc"}',
                        text: 'Highest Payout',
                      },
                      {
                        value: '{"default_payout": "asc"}',
                        text: 'Lowest Payout',
                      },
                      {
                        value: '{"epc_affiliation": "desc"}',
                        text: 'Highest EPC',
                      },
                      {
                        value: '{"epc_affiliation": "asc"}',
                        text: 'Lowest EPC',
                      },
                    ]}
                    showSelectedFieldCheckmark={false}
                    value={sortValue}
                  />
                </div>
              </div>
              <Pagination
                className="pull-right"
                currentPage={recentOffers.offersInfos.currentPage}
                handlePageChange={handleRecentPageChange}
                pageCount={recentOffers.offersInfos.pageCount}
                rowsPerPage={recentOffers.offers.length}
                totalResults={recentOffers.offersInfos.total}
              />
            </div>
            {viewMode === 'cards' ? (
              <OffersCards
                epcIconIsVisible={IS_VISIBLE.epcIcon}
                offers={recentOffers.offers}
                statusMapping={statusMapping}
                widthSize={windowWidthSize}
              />
            ) : (
              <OffersTable
                epcIconIsVisible={IS_VISIBLE.epcIcon}
                offers={recentOffers.offers}
                search={recentOffers.search}
                statusMapping={statusMapping}
              />
            )}
            <Pagination
              className="pull-right"
              currentPage={recentOffers.offersInfos.currentPage}
              handlePageChange={handleRecentPageChange}
              pageCount={recentOffers.offersInfos.pageCount}
              rowsPerPage={recentOffers.offers.length}
              totalResults={recentOffers.offersInfos.total}
            />
          </Container>
        </div>
      ) : null}

      {IS_VISIBLE.offersSearch && selectedTab === 'all' ? (
        <div className="row" id="offers-search-container">
          <OffersSearch
            approvalStatusMapping={statusMapping}
            categoriesList={categoriesList}
            countries={countries}
            dispatch={dispatch}
            handleSearchSubmit={handleSearchSubmit}
            isVisibleVideoModalIcon={IS_VISIBLE.itemVideoIcon}
            listChannels={listChannels}
            listConversionTypes={listConversionTypes}
            listCountries={listCountries}
            listMediaPlacements={listMediaPlacements}
            listVerticals={getAvailableVerticals()}
            offersIsFetching={offersIsFetching}
            offersSearch={offersSearch}
            query={query}
            search={search}
          />
        </div>
      ) : null}

      {IS_VISIBLE.offersSearch && selectedTab === 'all' ? (
        <div className="row">
          <div className="anchor" id="offers-list" />
          <Container
            className="z-depth-2 col-md-12 black-title"
            collapsable
            isOpen={IS_OPEN.offersList}
            loading={offersIsFetching}
            title={
              <span>
                <strong className="preview-icon">{offersInfos.total}</strong> <FormattedMessage {...messages.genericTextOffers} />
              </span>
            }
          >
            <div className="offers-list-header">
              <ViewMode
                classNames={['pull-left', 'hidden-xs', 'hidden-sm', 'viewmode']}
                currentViewMode={viewMode}
                handleViewModeChange={handleViewModeChange}
              />
              <div className="sort customSelects">
                <div className="sort-by fields" data-cy="sort-by-dropdown">
                  <SelectCustom
                    id="sorting"
                    label={messages.genericTextSortingLandingPage.description}
                    styleBox={true}
                    onChange={(e) => {
                      handleSort(JSON.parse(e));
                    }}
                    options={[
                      {
                        value: '{"featured": "desc"}',
                        text: 'Best Match',
                      },
                      {
                        value: '{"id": "desc"}',
                        text: 'Newest',
                      },
                      {
                        value: '{"default_payout": "desc"}',
                        text: 'Highest Payout',
                      },
                      {
                        value: '{"default_payout": "asc"}',
                        text: 'Lowest Payout',
                      },
                      {
                        value: '{"epc_affiliation": "desc"}',
                        text: 'Highest EPC',
                      },
                      {
                        value: '{"epc_affiliation": "asc"}',
                        text: 'Lowest EPC',
                      },
                    ]}
                    showSelectedFieldCheckmark={false}
                    value={sortValue}
                  />
                </div>
              </div>
              {windowWidthSize >= 1240 && getPaginationComponent('rowsPerPageTop')}
            </div>
            {viewMode === 'cards' ? (
              <OffersCards
                epcIconIsVisible={IS_VISIBLE.epcIcon}
                offers={offers}
                statusMapping={statusMapping}
                widthSize={windowWidthSize}
              />
            ) : (
              <OffersTable epcIconIsVisible={IS_VISIBLE.epcIcon} offers={offers} search={search} statusMapping={statusMapping} />
            )}
            {getPaginationComponent('rowsPerPageBottom')}
          </Container>
        </div>
      ) : null}
    </div>
  );
};

Offers.propTypes = {
  categoriesList: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  listChannels: PropTypes.array,
  listConversionTypes: PropTypes.array,
  listCountries: PropTypes.array,
  listMediaPlacements: PropTypes.array,
  listVerticals: PropTypes.array,
  location: PropTypes.object,
  news: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  offersInfos: PropTypes.object.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  offersSearch: PropTypes.object,
  offersSlides: PropTypes.array,
  offersViewMode: PropTypes.string.isRequired,
  profileSettings: PropTypes.object,
  recentOffers: PropTypes.object,
  search: PropTypes.object.isRequired,
  skinConfigurations: PropTypes.object.isRequired,
  topOffers: PropTypes.object,
};

export default connect((state) => ({
  allOffers: state.application.lists.allOffers,
  categoriesList: state.application.lists.offersCategories,
  countries: state.application.lists.countries,
  listChannels: state.application.lists.searchFields.Channels,
  listConversionTypes: state.application.lists.searchFields.ConversionTypes,
  listMediaPlacements: state.application.lists.searchFields.MediaPlacements,
  listVerticals: state.application.lists.searchFields.Verticals,
  listCountries: state.application.lists.countries,
  news: state.news.data,
  offers: state.offers.offers,
  offersInfos: state.offers.offersInfos,
  offersIsFetching: state.offers.offersIsFetching,
  offersLocationQuery: state.offers.offersLocationQuery,
  offersSearch: state.form.offersSearch,
  offersSlides: state.offersSlides.data,
  offersViewMode: state.application.ui.offersViewMode,
  profileSettings: state.profile.data.settings,
  recentOffers: state.topoffers.recent,
  search: state.offers.search,
  skinConfigurations: state.skinConfigurations.data,
  topOffers: state.topoffers.top,
  isConnected: state.login.isConnected,
}))(Offers);
