import { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import messages from '../../i18n/base-en.js';
import { Gtm } from '../../utils/gtm';

import { InputText } from '../reusables';

import { getCurrentPeriod, getLastPeriod } from '../../utils/helpers';
import { v4 as uuidv4 } from 'uuid';

import 'materialize-css';
import { Select } from 'react-materialize';

class DateRange extends Component {
  constructor(props) {
    super(props);

    this.changeDateStateAndFields = this.changeDateStateAndFields.bind(this);
    this.handleCalendarClickOutside = this.handleCalendarClickOutside.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEndFocus = this.onEndFocus.bind(this);
    this.onStartFocus = this.onStartFocus.bind(this);

    let startDate = undefined;
    let endDate = undefined;

    if (props.startField.value && props.endField.value) {
      startDate = moment(props.startField.value);
      endDate = moment(props.endField.value);
    }

    this.state = {
      startDate,
      endDate,
      isStartOpen: 'is-closed',
      isEndOpen: 'is-closed',
    };

    this.presets = [
      [
        {
          action: () => this.changeDateStateAndFields(moment(), moment().add(1, 'days')),
          label: 'Today',
        },

        {
          action: () => this.changeDateStateAndFields(moment().subtract(1, 'days'), moment().subtract(1, 'days')),
          label: 'Yesterday',
        },
        {
          action: () => this.changeDateStateAndFields(moment().subtract(7, 'days'), moment()),
          label: 'Last 7 Days',
        },
        {
          action: () => {
            const { dateStart, dateEnd } = getCurrentPeriod();

            this.changeDateStateAndFields(moment(dateStart), moment(dateEnd));
          },
          label: 'This Period',
        },

        {
          action: () => {
            const { dateStart, dateEnd } = getLastPeriod();

            this.changeDateStateAndFields(moment(dateStart), moment(dateEnd));
          },
          label: 'Last Period',
        },

        {
          action: () => this.changeDateStateAndFields(moment().startOf('week'), moment().endOf('week')),
          label: 'This Week',
        },

        {
          action: () =>
            this.changeDateStateAndFields(
              moment().subtract(1, 'week').startOf('week'),
              moment().subtract(1, 'week').endOf('week')
            ),
          label: 'Last Week',
        },
      ],
      [
        {
          action: () => this.changeDateStateAndFields(moment().startOf('month'), moment().endOf('month')),
          label: 'This Month',
        },

        {
          action: () =>
            this.changeDateStateAndFields(
              moment().subtract(1, 'month').startOf('month'),
              moment().subtract(1, 'month').endOf('month')
            ),
          label: 'Last Month',
        },

        {
          action: () => this.changeDateStateAndFields(moment().startOf('quarter'), moment().endOf('quarter')),
          label: 'This Quarter',
        },

        {
          action: () =>
            this.changeDateStateAndFields(
              moment().subtract(1, 'quarter').startOf('quarter'),
              moment().subtract(1, 'quarter').endOf('quarter')
            ),
          label: 'Last Quarter',
        },

        {
          action: () => this.changeDateStateAndFields(moment().startOf('year'), moment().endOf('year')),
          label: 'This Year',
        },

        {
          action: () =>
            this.changeDateStateAndFields(
              moment().subtract(1, 'year').startOf('year'),
              moment().subtract(1, 'year').endOf('year')
            ),
          label: 'Last Year',
        },

        {
          action: () => this.changeDateStateAndFields(moment('2015-01-01'), moment().add(1, 'days')),
          label: 'All Time',
        },
      ],
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { presets, startField, endField } = this.props;
    const startDate = nextProps.startField.value ? moment(nextProps.startField.value) : moment().startOf('quarter');
    const endDate = nextProps.endField.value ? moment(nextProps.endField.value) : moment().endOf('quarter');

    this.setState({
      startDate,
      endDate,
    });

    if (!_.isEqual(presets, nextProps.presets)) {
      const preset = _.flatten(this.presets).find((currentPreset) => currentPreset.label === nextProps.presets.value);
      if (preset) {
        preset.action();
      }
    }

    if (!_.isEqual(startField, nextProps.startField) && typeof nextProps.startField.value !== 'string') {
      startField.onChange(nextProps.startField.value.format('YYYY-MM-DD'));
    }

    if (!_.isEqual(endField, nextProps.endField) && typeof nextProps.endField.value !== 'string') {
      endField.onChange(nextProps.endField.value.format('YYYY-MM-DD'));
    }
  }

  onStartFocus() {
    this.setState({
      isStartOpen: 'is-open',
      isEndOpen: 'is-closed',
    });
  }

  onEndFocus() {
    this.setState({
      isStartOpen: 'is-open',
      isEndOpen: 'is-closed',
    });
  }

  changeDateStateAndFields(startDate, endDate) {
    const { dispatch, startField, endField } = this.props;
    this.setState({
      startDate,
      endDate,
    });

    const promises = [];
    if (startField) {
      promises.push(dispatch(startField.onChange(startDate.format('YYYY-MM-DD'))));
    }
    if (endField) {
      promises.push(endField.onChange(endDate.format('YYYY-MM-DD')));
    }
    Promise.all(promises);
  }

  handleChangeEnd(endDate) {
    this.handleChange({ start: endDate });
  }

  handleChangeStart(startDate) {
    this.handleChange({ start: startDate });
  }

  handleSubmit() {
    this.setState({
      isStartOpen: 'is-closed',
      isEndOpen: 'is-closed',
    });
  }

  handleCancel() {
    this.setState({
      startDate: undefined,
      endDate: undefined,
      isStartOpen: 'is-closed',
      isEndOpen: 'is-closed',
    });
  }

  handleCalendarClickOutside() {
    this.setState({
      isStartOpen: 'is-closed',
      isEndOpen: 'is-closed',
    });
  }

  handleChange({ start, end }) {
    const { startField, endField } = this.props;
    const { startDate, endDate } = this.state;

    let startDateValue = start || startDate;
    let endDateValue = end || endDate;

    const startDateInput = document.getElementById('datepicker__startdate');
    const endDateInput = document.getElementById('datepicker__enddate');

    if (startDateInput.value === startDate.format('YYYY-MM-DD') && endDateInput.value === endDate.format('YYYY-MM-DD')) {
      if (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
        endDateValue = startDateValue;
      }
    } else {
      if (startDateInput.value === startDate.format('YYYY-MM-DD')) {
        endDateValue = startDateValue;
        startDateValue = startDate;
      }
    }

    if (startDateValue && startDateValue.isAfter(endDateValue)) {
      const temp = startDateValue;
      startDateValue = endDateValue;
      endDateValue = temp;
    }

    if (startField) {
      startField.onChange(startDateValue);
    }
    if (endField) {
      endField.onChange(endDateValue);
    }

    this.setState({
      startDate: startDateValue,
      endDate: endDateValue,
    });
  }

  render() {
    const { endField, onBlur, popoverTarget, presets, startField, gtmEvent } = this.props;
    const { isStartOpen, isEndOpen, startDate, endDate } = this.state;

    const isMobile = () => screen.width <= 991; // eslint-disable-line

    const handleOnChange = (date) => {
      presets.onChange(date);
      if (gtmEvent) {
        Gtm.event('payment history', 'click', `date dropdown - ${date}`);
      }
    };

    const dropdownDatePreset = (device) => (
      <div className="row" id={`date-present-${device}`}>
        <Select
          className="col-md-12"
          id="presets"
          label={messages.statisticsDatePresets.description}
          onChange={(e) => handleOnChange(e)}
          tabIndex={140}
          value={presets.value}
        >
          {_.flatten(this.presets).map((preset) => (
            <option key={uuidv4()} value={preset.label}>
              {preset.label}
            </option>
          ))}
        </Select>
      </div>
    );
    return (
      <div className="row" id="daterange-component">
        <div className="col-md-12">
          {isMobile() && dropdownDatePreset('mobile')}
          <div className="row">
            <label className="col-md-1 col-sm-2 col-xs-2" htmlFor="datepicker__startdate">
              <i className="material-icons calendar-icon">&#xE916;</i>
            </label>
            <div className="col-lg-8 col-md-8 col-sm-10 col-xs-10">
              <div className="row" id="container-select-date">
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <label className="hidden-sm hidden-xs" htmlFor="datepicker__startdate">
                    <FormattedMessage {...messages.statisticsStartDate} />
                  </label>
                  <span className="hidden-sm hidden-xs">
                    <DatePicker
                      id="datepicker__startdate"
                      {...startField}
                      className={isStartOpen}
                      dateFormat="YYYY-MM-DD"
                      endDate={endDate}
                      handleCalendarClickOutside={this.handleCalendarClickOutside}
                      handleCancel={this.handleCancel}
                      handleSubmit={this.handleSubmit}
                      onBlur={onBlur}
                      onChange={this.handleChangeStart}
                      onFocus={this.onStartFocus}
                      popoverAttachment="top left"
                      popoverTarget={popoverTarget || '#daterange-component-container'}
                      popoverTargetOffset="-15px -25px"
                      presets={this.presets}
                      selected={startDate}
                      startDate={startDate}
                      tetherConstraints={[{ to: 'scrollParent' }]}
                    />
                  </span>
                  <span className="hidden-xlg hidden-lg hidden-md">
                    <InputText field={startField} id="calendarStartDate" label={messages.statisticsStartDate} type="date" />
                  </span>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                  <label className="hidden-sm hidden-xs" htmlFor="datepicker__date">
                    <FormattedMessage {...messages.statisticsEndDate} />
                  </label>
                  <span className="hidden-sm hidden-xs">
                    <DatePicker
                      id="datepicker__enddate"
                      {...endField}
                      className={isEndOpen}
                      dateFormat="YYYY-MM-DD"
                      endDate={endDate}
                      handleCalendarClickOutside={this.handleCalendarClickOutside}
                      handleCancel={this.handleCancel}
                      handleSubmit={this.handleSubmit}
                      onBlur={onBlur}
                      onChange={this.handleChangeEnd}
                      onFocus={this.onEndFocus}
                      popoverAttachment="top left"
                      popoverTarget={popoverTarget || '#daterange-component-container'}
                      popoverTargetOffset="-15px -25px"
                      presets={this.presets}
                      selected={endDate}
                      startDate={startDate}
                      tetherConstraints={[{ to: 'scrollParent' }]}
                    />
                  </span>
                  <span className="hidden-xlg hidden-lg hidden-md">
                    <InputText field={endField} id="calendarEndDate" label={messages.statisticsEndDate} type="date" />
                  </span>
                </div>
                {!isMobile() && dropdownDatePreset('desktop')}
              </div>
            </div>
          </div>
          <div id="daterange-component-container" />
        </div>
      </div>
    );
  }
}

DateRange.propTypes = {
  dispatch: PropTypes.func.isRequired,
  endField: PropTypes.object,
  onBlur: PropTypes.func,
  popoverTarget: PropTypes.string,
  presets: PropTypes.object,
  startField: PropTypes.object,
};

export default DateRange;
