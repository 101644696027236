import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { provideHooks } from 'redial';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { browserHistory } from 'react-router';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import BannerProfileCompleted from './sections/BannerProfileCompleted.react';
import Footer from './sections/Footer.react';
import Header from './sections/Header.react';
import LoadingBar from './sections/LoadingBar.react';
import MainNavigation from './sections/MainNavigation/MainNavigation';
import PageTitle from './sections/PageTitle.react';
import ReturnMessage from './sections/ReturnMessage.react';

import { Styles } from '../components/reusables';

import SkinConfigurationsUtils from '../utils/SkinConfigurationsUtils';
import { initialFetching, initialDefering } from '../utils/initialFetching';
import { changeAllViewModes, changeMainMenuState } from '../components/Application/actions/application-actions';
import { logout } from '../components/Login/actions/login-actions';

import * as i18n from '../i18n/';

import messages from '../i18n/base-en.js';

import config from '../config';
import { Gtm } from '../utils/gtm';
import ModalComponent from './Modal/modal.component';
import { openModal } from './Modal/modal-actions/modal-actions';
import intercom from '../utils/intercom';

const { UA_ID, OPT_ID, GA4_ID } = config;

const App = ({
  affId,
  affStatus,
  application,
  children,
  dispatch,
  gamificationBagesIsFetching,
  gamificationEventsIsFetching,
  gamificationEventsData,
  isConnected,
  location,
  newAlerts,
  newAlertsIsFetching,
  profileCompleted,
  routes,
  skinConfigurations,
  user,
  shouldModalShow,
}) => {
  const applicationRef = useRef();

  useEffect(() => {
    let currentWidth = 0;

    window.onresize = function (force = false) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (currentWidth !== width || force === true) {
        if (width <= 991) {
          dispatch(changeAllViewModes('cards', width));
        } else {
          dispatch(changeAllViewModes('table', width));
        }
        window.crakrevenue.resizingFnStackCalls();
      }
      currentWidth = width;
    };
    window.onresize();

    Gtm.loadGTMScript();
  }, []);

  useEffect(() => {
    const isFrontend = typeof window !== 'undefined' && typeof document !== 'undefined';
    const mainMenuIsOpen = application.ui.mainMenuIsOpen;
    if (isFrontend) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (width <= 991 && mainMenuIsOpen) {
        dispatch(changeMainMenuState(false));
        const scrollY = document.getElementById('page-wrap').style.top;
        document.getElementById('page-wrap').style.position = '';
        document.getElementById('page-wrap').style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'UA_CRData',
      affId: affId,
      GA4_ID: GA4_ID,
      UA_ID: UA_ID,
      optimizeContainerId: OPT_ID,
    });
  }, [routes]);
  useEffect(() => {
    const url = new URL(window.location.href);

    const streakWeek = parseInt(url.searchParams.get('streakweek'));
    const isBrokenModal = url.searchParams.get('isBrokenModal') === 'true';

    if (isConnected) {
      if (!gamificationEventsIsFetching && !gamificationBagesIsFetching) {
        if (gamificationEventsData?.status === 'progress' || (streakWeek >= 1 && !isBrokenModal)) {
          dispatch(
            openModal({
              name: 'ContinuationStreakModal',
              modalProps: {
                dispatch,
                className: 'gamification-streak-modal',
                streakWeek,
                isImpression: true,
              },
            })
          );
        } else if (gamificationEventsData?.status === 'new' || streakWeek === 0) {
          dispatch(
            openModal({
              name: 'WeekZeroModal',
              modalProps: {
                dispatch,
                className: 'gamification-streak-modal',
                streakWeek,
                isImpression: true,
              },
            })
          );
        } else if (gamificationEventsData?.status === 'end' || (streakWeek >= 1 && isBrokenModal)) {
          dispatch(
            openModal({
              name: 'BrokenStreakModal',
              modalProps: {
                dispatch,
                className: 'gamification-streak-modal',
                streakWeek,
                isImpression: true,
              },
            })
          );
        }
        intercom.trackEvent('Gamification Streak', {
          last_connection_streak: gamificationEventsData?.affiliateProgress.progressData.streak,
        });
      }
    }
  }, [gamificationEventsIsFetching, gamificationBagesIsFetching]);

  useEffect(() => {
    if (routes[routes.length - 1].path === 'login' && !('id' in user) && 'id' in user) {
      browserHistory.push('/');
    }
  }, [user, routes]);

  useEffect(() => {
    window.crakrevenue.resizingFnStackCalls();
  }, [application.ui.mainMenuIsOpen]);

  const handleLogout = (e) => {
    e.preventDefault();

    Promise.all([dispatch(logout())]).then(browserHistory.push('/login'));
  };

  const showLoadingCursor = () => {
    if (applicationRef.current) {
      applicationRef.current.classList.add('isLoading');
    }
  };

  const hideLoadingCursor = () => {
    if (applicationRef.current) {
      applicationRef.current.classList.remove('isLoading');
    }
  };

  let appClasses = location.pathname.split('/');
  appClasses.shift();
  const isServer = typeof window !== 'undefined';

  const SKIN = new SkinConfigurationsUtils(skinConfigurations.data);
  if (!application.showApplication) {
    appClasses.push('notMounted');
  }
  if (!isConnected) {
    appClasses.push('isdisabled');
  }
  if (isServer) {
    if (application.ui.mainMenuIsOpen) {
      appClasses.push('menuOpen');
      document.body.classList.add('menu-mobile-open');
    } else {
      document.body.classList.remove('menu-mobile-open');
    }
  }

  const intlData = {
    locale: application.language,
    messages: i18n[application.language],
  };

  if (!skinConfigurations.isFetching) {
    const SKIN_NAME = SkinConfigurationsUtils.getSkinName(skinConfigurations.data).toLowerCase();
    appClasses = [SKIN_NAME, ...appClasses];

    if (SKIN_NAME === 'mfc') {
      routes[0].title = messages.mfc;
    } else if (SKIN_NAME === 'whaleshub') {
      routes[0].title = messages.whaleshub;
    }

    return (
      <>
        <Helmet>
          <meta content={SKIN.getPropertyValue('Global', 'OGTitle', 'content') || ''} property="og:title" />
          <meta content={SKIN.getPropertyValue('Global', 'OGDescription', 'content') || ''} property="og:description" />
          <meta content={SKIN.getPropertyValue('Global', 'OGUrl', 'content') || ''} property="og:url" />
        </Helmet>
        <IntlProvider key="intl" {...intlData}>
          <div className={`${appClasses.join(' ').trim()}`} id="application" ref={applicationRef}>
            <PageTitle routes={routes} />
            <LoadingBar hideLoadingCursor={hideLoadingCursor} showLoadingCursor={showLoadingCursor} />
            <div id="page-wrap">
              <Header
                affStatus={affStatus}
                dispatch={dispatch}
                handleLogout={(e) => handleLogout(e)}
                isConnected={isConnected}
                mainMenuIsOpen={application.ui.mainMenuIsOpen}
                newAlerts={newAlerts}
                newAlertsIsFetching={newAlertsIsFetching}
                routes={routes}
                user={user}
              />
              {isConnected ? (
                <MainNavigation
                  dispatch={dispatch}
                  handleLogout={(e) => handleLogout(e)}
                  isConnected={isConnected}
                  location={location}
                  mainMenuIsOpen={application.ui.mainMenuIsOpen}
                />
              ) : null}
              {routes[routes.length - 2].path !== 'profile' && isConnected ? (
                <BannerProfileCompleted dispatch={dispatch} profileCompleted={profileCompleted} />
              ) : null}
              {React.cloneElement(children, {
                language: application.language,
              })}

              {shouldModalShow ? <ModalComponent dispatch={dispatch} /> : null}
              <Footer />
            </div>
            {isConnected ||
            routes[routes.length - 1].path === 'password-reset' ||
            routes[routes.length - 1].path === 'tell-us-about-you' ? (
              <ReturnMessage {...application.ui.actionMessage} dispatch={dispatch} />
            ) : null}
            <Styles />
            <Favicon url={`/favicons/${SKIN_NAME}/favicon.ico`} />
          </div>
        </IntlProvider>
      </>
    );
  }
  return (
    <IntlProvider key="intl" {...intlData}>
      <div className={`${appClasses} ${shouldModalShow ? 'hideContent' : ''}`} id="application" ref={applicationRef} />
    </IntlProvider>
  );
};

App.propTypes = {
  affStatus: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
  children: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  isConnected: PropTypes.bool.isRequired,
  location: PropTypes.object,
  newAlerts: PropTypes.array.isRequired,
  profileCompleted: PropTypes.object.isRequired,
  routes: PropTypes.array,
  skinConfigurations: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const hooks = {
  fetch: ({ dispatch }) => initialFetching(dispatch),
  defer: ({ dispatch, state }) => initialDefering(dispatch, state),
};

export default provideHooks(hooks)(
  connect((state) => ({
    application: state.application,
    isConnected: state.login.isConnected,
    user: state.profile.data.affUserInfos,
    affStatus: state.profile.data.affStatus,
    affId: state.profile.data.affUserInfos.affiliate_id,
    newAlerts: state.alerts.newAlerts,
    newAlertsIsFetching: state.alerts.newAlertsIsFetching,
    profileCompleted: state.profileCompleted,
    skinConfigurations: state.skinConfigurations,
    shouldModalShow: state.modal.shouldModalShow,
    gamificationEventsIsFetching: state.gamification?.eventsDataIsFetching,
    gamificationBagesIsFetching: state.gamification?.badgeDataIsFetching,
    gamificationEventsData: state.gamification?.eventsData?.notification,
  }))(App)
);
