import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Gtm } from '../../utils/gtm';
import messages from '../../i18n/base-en.js';

import OffersImage from './OffersImage.react';
import { Payout, PayoutType, Table, Targeting, CountryList } from '../../components/reusables';

import Tooltip from '../reusables/ReactTooltip';
import NewIcon from '../reusables/svg/MenuIcons/NewIcon.js';

class OffersTable extends Component {
  render() {
    const { offers, statusMapping, intl, epcIconIsVisible } = this.props;

    const getOfferLink = (offerId, offerLinkChild, i, linkLabel, isOfferName = false) => (
      <Link
        className={isOfferName ? 'offer-name' : ''}
        data-cy={`offerlink:${i}-${offerId}`}
        onClick={() => {
          Gtm.event('offers', 'Click', `${linkLabel}`);
        }}
        onlyActiveOnIndex={false}
        to={`/offers/${offerId}`}
      >
        {offerLinkChild}
      </Link>
    );
    const table = {
      head: [
        { component: <span>&nbsp;</span>, sort: 'approval_status' },
        {
          component: <FormattedMessage {...messages.genericTextId} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextPreview} />,
        },
        { component: <span>&nbsp;</span>, sort: 'featured' },
        {
          component: <FormattedMessage {...messages.genericTextName} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextVertical} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextTargeting} />,
        },
        {
          component: <FormattedMessage {...messages.genericTextPayout} />,
        },
        {
          component: <FormattedMessage {...messages.offerEPC} />,
          helpLink: 'https://support.crakrevenue.com/knowledge-base/epc-definition/',
        },
        {
          component: <FormattedMessage {...messages.genericTextPayoutType} />,
        },
        {
          component: <FormattedMessage {...messages.genericTopPerformingCountries} />,
        },
        '',
      ],
      body: offers.map((offer) => [
        <Tooltip delay={50} effect="float" key={offer.id} tooltip={statusMapping[offer.approval_status]}>
          <div
            className={`offer-status ${offer.approval_status} ${
              ['approved', 'pending'].indexOf(offer.approval_status) !== -1 ? 'bg-primary-color' : ''
            }`}
          />
        </Tooltip>,
        getOfferLink(offer.id, offer.id, 1, `offer id - ${offer.id}`),
        getOfferLink(
          offer.id,
          <div style={{ display: 'flex', position: 'relative' }}>
            <OffersImage
              alt={offer.name}
              borderRadius="15px"
              fontSize="13px"
              height="50px"
              thumbnail={offer.thumbnail}
              width="50px"
            />
            {!!offer.tags && offer.tags.some((tag) => tag.name === 'New') ? (
              <div className="icon-new">
                <NewIcon />
              </div>
            ) : null}
          </div>,
          2,
          `offer preview - ${offer.name}`
        ),
        <div key={offer.id}>
          {!!offer.featured && <img alt="Featured" height="17" src="/img/featured-star.svg" width="16" />}
        </div>,
        getOfferLink(offer.id, offer.name, 3, `offer name - ${offer.name}`, true),
        <div key={offer.id}>{offer.verticals.map((ver) => ver.name).join(', ')}</div>,
        <div key={offer.id}>
          <Targeting targeting={offer.channels.map((chan) => chan.name)} />
        </div>,
        <div key={offer.id}>
          <Payout
            conversionTypes={offer.conversion_types.map((ct) => ct.name)}
            defaultPayout={offer.default_payout}
            payoutType={offer.payout_type}
            percentPayout={offer.percent_payout}
          />
        </div>,
        <div key={offer.id}>
          {offer.epc_affiliation ? (
            <FormattedNumber
              currency="USD"
              minimumFractionDigits={4}
              style="currency"
              value={parseFloat(offer.epc_affiliation || 0)}
            />
          ) : (
            <span className="no-epc" title={intl.formatMessage(messages.notEnoughData)}>
              <FormattedMessage {...messages.notAvailable} />
            </span>
          )}
        </div>,
        <div key={offer.id}>
          <PayoutType categories={offer.conversion_types.map((ct) => ct.name)} />
        </div>,
        <div className="centered" key={offer.id}>
          {offer.top_countries ? (
            <CountryList list={offer.top_countries} />
          ) : (
            <span className="no-epc" title={intl.formatMessage(messages.notEnoughData)}>
              <FormattedMessage {...messages.notAvailable} />
            </span>
          )}
        </div>,
        <div className="view-site primary-color" key={offer.id}>
          <a
            href={offer.preview_url}
            onClick={() => {
              Gtm.event('offers', 'Click', 'view site');
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="material-icons big">remove_red_eye</i> <FormattedMessage {...messages.genericTextViewSite} />
          </a>
        </div>,
      ]),
    };

    return (
      <div id="offers-table">
        <div className="row">
          <Table epcIconIsVisible={epcIconIsVisible} table={table} />
        </div>
      </div>
    );
  }
}

OffersTable.propTypes = {
  offers: PropTypes.array.isRequired,
  search: PropTypes.object.isRequired,
  statusMapping: PropTypes.object.isRequired,
};

export default injectIntl(OffersTable);
