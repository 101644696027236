import { reduxForm } from 'redux-form';
import { Radio, InputText, LoadingPage } from '../../components/reusables';
import validate from './validateIdentityInfos';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import messages from '../../i18n/base-en';
import ExcludedCountriesHelper from '../../utils/ExcludedCountriesHelper';
import { initialFetching } from '../../utils/initialFetching';
import { useEffect, useState } from 'react';
import provincesList from '../../utils/list-options/provinces';
import { putAffiliateBeneficiary } from '../../actions/affiliate-beneficiary-actions';
import { postCreateCompany, putUpdateCompany } from '../../actions/affiliate-company-actions';
import MessageInfo from '../../components/reusables/MessageInfo.react';
import { openModal } from '../../components/Modal/modal-actions/modal-actions';
import statesList from '../../utils/list-options/states';
import CompleteProfileComponent from '../../components/Profile/CompleteProfile/CompleteProfileComponent';
import CompleteProfileService from '../../components/Profile/CompleteProfile/CompleteProfileService';
import { ValidationStatusEnum } from '../../enum/ValidationStatusEnum';
import { AffiliateTypeEnum } from '../../enum/AffiliateTypeEnum';
import CompleteBeneficiaryInfoService from '../../components/Profile/CompleteBeneficiaryInfo/CompleteBeneficiaryInfoService';
import { Link } from 'react-router';
import CompleteBillingInformationService from '../../components/Profile/CompleteBillingInfo/CompleteBillingInformationService';
import { Gtm } from '../../utils/gtm';
import { putBillingInfos } from '../../actions/billing-infos-actions';
import config from '../../config';
import ActionMessageHelper, { COMPANY_INFOS_SUCCESS } from '../../utils/ActionMessageHelper';
import moment from 'moment';
import { fetchReferrals } from '../../actions/referrals-actions';
import HasEarnedRevenuesService from '../../components/Profile/HasEarnedReferralRevenues/HasEarnedRevenuesService';
import HasToEarnRevenues from '../../components/Profile/HasEarnedReferralRevenues/HasToEarnRevenues.react';
import { updateProfile } from '../../actions/profile-actions';
import { useConfirmationOnFields } from '../../components/reusables/useConfirmationOnFields';

import 'materialize-css';
import SelectCustom from '../../components/reusables/form/SelectCustom';

const { PAYMENT_METHOD_BITCOIN_ID } = config;

export const getAffiliateValidationStatusName = (id, listValidationStatus) => {
  const result = listValidationStatus.filter((status) => status.id === id);
  return result[0]?.name.toUpperCase();
};

function Identity({
  countries,
  dispatch,
  fields: { profileIdentity, country, registrationNumber, gstTaxId, pstTaxId, province, company_name, state },
  invalid,
  errors,
  affiliateValidationStatus,
  listValidationStatus,
  accountType,
  affId,
  affCompany,
  listAffiliateTypes,
  beneficiaryInfos,
  affInfos,
  affiliate,
  referralsSettings,
  referralsTimeStamp,
  referrals,
  payoutTotal,
  isFetchingPayoutTotal,
  router,
  route,
}) {
  const [hasEarnedRevenues, setHasEarnedRevenues] = useState(false);
  const dataLoading = isFetchingPayoutTotal;

  const UNVERIFIED = 'UNVERIFIED';

  const hasToCompleteBeneficiaryInfo = CompleteBeneficiaryInfoService.hasToCompleteBeneficiaryInfo({
    affBeneficiary: beneficiaryInfos,
  });

  const hasToCompleteBillingInfo = CompleteBillingInformationService.hasToCompleteBillingInfo(
    affiliate?.minimum_payout.payment_method_id,
    affiliate?.payment_method
  );

  let messageInfo;

  useEffect(() => {
    if (referralsTimeStamp <= Date.now() - 600000) {
      const defaultReferralsSettings = referralsSettings;
      const updatedReferralSettings = {
        ...defaultReferralsSettings,
        filters: {
          'Stat.date': {
            conditional: 'BETWEEN',
            values: [defaultReferralsSettings.filters['Stat.date'].values[0], moment().format('YYYY-MM-DD')],
          },
        },
      };
      dispatch(fetchReferrals(updatedReferralSettings));
    }
    initialFetching(dispatch);
  }, []);

  useEffect(() => {
    setHasEarnedRevenues(HasEarnedRevenuesService.hasEarnedRevenues(referrals, payoutTotal, accountType));
  }, [payoutTotal, accountType, referrals]);

  const filteredCountries = ExcludedCountriesHelper.getFilteredCountries(
    countries,
    ExcludedCountriesHelper.getProfileExcludeCountries()
  );

  const getAffiliateTypeId = (type) => {
    const temp = listAffiliateTypes.filter((affType) => affType.type.toLowerCase() === type.toLowerCase());

    return temp[0]?.id;
  };

  const [, setNeedConfirm] = useConfirmationOnFields({
    router,
    route,
    items: {
      profileIdentity,
      country,
      registrationNumber,
      gstTaxId,
      pstTaxId,
      province,
      company_name,
      state,
    },
    alertMessage: messages.profileSettingsUnsavedChecked.description,
  });

  const handleSubmitCompany = () => {
    let subCode = '';
    switch (country.value) {
      case 'CA':
        subCode = province.value;
        break;
      case 'US':
        subCode = state.value;
        break;
      default:
        subCode = null;
    }

    let beneficiary = {
      name: company_name.value,
      country_code: country.value,
      subdivision: subCode || null,
    };
    if (!accountType || accountType?.toUpperCase() !== AffiliateTypeEnum.COMPANY) {
      dispatch(
        updateProfile({
          affiliate_type_id: getAffiliateTypeId('Company'),
        })
      );
      beneficiary = {
        ...beneficiary,
        postal_code: null,
        city: null,
        address_line1: null,
        address_line2: null,
        address_line3: null,
        address_line4: null,
        address_line5: null,
        apartment_suite_unit_building: null,
      };
    }
    dispatch(putAffiliateBeneficiary(beneficiary));

    const company = {
      affId,
      name: company_name.value,
      registration_country_code: country.value,
      registration_number: registrationNumber.value,
      registration_subdivision_code: subCode,
      tax_id_gst_hst: country.value !== 'CA' ? null : gstTaxId.value,
      tax_id_pst: country.value !== 'CA' ? null : pstTaxId.value,
    };
    if (!affCompany) {
      dispatch(postCreateCompany(company)).then(() => {
        ActionMessageHelper.showSuccess(dispatch, COMPANY_INFOS_SUCCESS);
      });
    } else {
      dispatch(putUpdateCompany(company)).then(() => {
        ActionMessageHelper.showSuccess(dispatch, COMPANY_INFOS_SUCCESS);
      });
    }

    if (company && affiliate.payment_method_id === PAYMENT_METHOD_BITCOIN_ID) {
      // reset payment method
      affiliate.payment_method_id = null;
      affiliate.minimum_payout_id = null;
      affiliate.payment_method = {};
      affiliate.minimum_payout = {};

      dispatch(putBillingInfos(affiliate));
    }

    setNeedConfirm(() => false);

    Gtm.event('profile - identity', 'Click', `Save Changes - Company`);
  };

  const handleJumioClick = () => {
    dispatch(
      openModal({
        name: 'JumioRedirectModal',
        modalProps: {
          dispatch,
          getAffiliateTypeId,
          isLarge: true,
          className: 'jumio-modal-size',
        },
      })
    );
    // Update the field radio button which was not
    profileIdentity.value = 'Person';
  };

  const getValidationStatusName = (id, accountType) => {
    if (!id || !accountType || accountType?.toUpperCase() === AffiliateTypeEnum.COMPANY) {
      return UNVERIFIED;
    }

    return getAffiliateValidationStatusName(id, listValidationStatus);
  };

  const hasToCompleteProfile = CompleteProfileService.hasToCompleteProfile({
    affInfos,
  });

  const validationStatusName = getValidationStatusName(affiliateValidationStatus?.validation_status_id, accountType);

  const hasToShowButtonValidation = validationStatusName === ValidationStatusEnum.FAILED || validationStatusName === UNVERIFIED;

  switch (validationStatusName) {
    case ValidationStatusEnum.PROCESSING:
      messageInfo = {
        identityStatusMessage: {
          id: 'processing',
          defaultMessage:
            'We are doing a few extra validations on your account. <br/> Please contact support if your status remains PROCESSING for more than 5 business days.',
        },
      };
      break;
    case ValidationStatusEnum.REJECTED:
      messageInfo = {
        identityStatusMessage: {
          id: 'rejected',
          defaultMessage:
            'We are sorry, your identity validation has been rejected. <br/> Please contact CrakRevenue support for assistance.',
        },
      };
      break;
    case ValidationStatusEnum.FAILED:
      messageInfo = {
        identityStatusMessage: {
          id: 'failed',
          defaultMessage:
            'Something went wrong during the validation process. <br/> You can try again. Make sure the lighting is good in your room or refer to Jumio <a href="https://www.jumio.com/faq/" target="_blank">FAQ</a>.',
        },
      };
      break;
    case ValidationStatusEnum.PENDING:
      messageInfo = {
        identityStatusMessage: {
          id: 'pending',
          defaultMessage:
            'Processing your identity may take a few minutes. <br/> You can refresh for status updates. Please contact support if your status remains PENDING for more than 30 minutes.',
        },
      };
      break;
    case ValidationStatusEnum.VERIFIED:
      if (hasToCompleteBeneficiaryInfo || hasToCompleteBillingInfo) {
        messageInfo = {
          messageWithInternalLink: [
            'Congratulations, you have successfully validated your identity! Please  ',
            <Link key="/profile/billing-infos" to="/profile/billing-infos">
              complete your billing infos
            </Link>,
            ' to finalize your account setup. If needed, you can restart the validation process ',
          ],
          onClick: () => {
            handleJumioClick();
          },
          restart: true,
        };
      } else {
        messageInfo = {
          identityStatusMessage: {
            id: 'verified',
            defaultMessage: 'If needed, you can restart the validation process ',
          },
          onClick: () => {
            handleJumioClick();
          },
          restart: true,
        };
      }

      break;
    default:
      messageInfo = {
        identityStatusMessage: {
          id: 'default',
          defaultMessage: 'default',
        },
      };
  }
  return (
    <div className="app-container container-fluid form-container" id="profile-identity-section">
      {dataLoading ? <LoadingPage /> : null}
      {hasToCompleteProfile ? <CompleteProfileComponent /> : null}
      {!hasToCompleteProfile && !hasEarnedRevenues && <HasToEarnRevenues />}
      <div
        className={`user-details-wrapper  ${hasToCompleteProfile || !hasEarnedRevenues ? 'locked' : ''}`}
        id="profile-identity"
      >
        <div>
          <h1>Account Type</h1>
          <FormattedMessage {...messages.profileIdentityInformationText} />
          <div className="bottom-row">
            <div className="radio-section">
              <Radio field={profileIdentity} id="identity-person" label={messages.profileIdentityPerson} value="Person" />

              <Radio
                disabled={
                  validationStatusName === ValidationStatusEnum.PENDING ||
                  validationStatusName === ValidationStatusEnum.PROCESSING
                }
                field={profileIdentity}
                id="identity-company"
                label={messages.profileIdentityCompany}
                value="Company"
              />
            </div>
            {profileIdentity.value?.toUpperCase() === AffiliateTypeEnum.PERSON && (
              <span className="status">
                Status:
                {` ${validationStatusName}`}
              </span>
            )}
          </div>
          {profileIdentity.value?.toUpperCase() === AffiliateTypeEnum.COMPANY && (
            <div className="company-validate ">
              <div className="row customSelects">
                <InputText
                  className="col-md-12"
                  displayErrorInstantly
                  field={company_name}
                  id="company-name-id"
                  label={{
                    ...messages.genericTextCompanyName,
                    defaultMessage: "Company's Name",
                  }}
                  labelClasses=""
                  type="text"
                />
                <div className="col-md-12 fields" data-cy="select-wrapper-country-identity">
                  <SelectCustom
                    id="country"
                    label={messages.genericTextCountry.description}
                    onChange={country.onChange}
                    touched={country.onChange}
                    error={country.error}
                    tabIndex={130}
                    value={country.value || ''}
                    valueDisabled={'Choose'}
                    showSelectedFieldCheckmark={false}
                    options={filteredCountries?.map((m, i) => ({ value: m.code, text: m.name }))}
                    enableSearch
                  />
                </div>
                {country.value === 'CA' && (
                  <div className="col-md-12 fields" data-cy="select-wrapper-province-identity">
                    <SelectCustom
                      id="identity-province"
                      label={messages.genericTextProvince.description}
                      onChange={province.onChange}
                      tabIndex={131}
                      value={province.value || ''}
                      valueDisabled={'Choose'}
                      showSelectedFieldCheckmark={false}
                      touched={province.onChange}
                      error={province.error}
                      options={provincesList?.map((m, i) => ({ value: m.value, text: m.label.defaultMessage }))}
                    />
                  </div>
                )}
                {country.value === 'US' && (
                  <div className="col-md-12 fields">
                    <SelectCustom
                      id="identity-state"
                      label={messages.genericTextState.description}
                      onChange={state.onChange}
                      tabIndex={131}
                      value={state.value || ''}
                      valueDisabled={'Choose'}
                      showSelectedFieldCheckmark={false}
                      touched={state.onChange}
                      error={state.error}
                      options={statesList?.map((m, i) => ({ value: m.value, text: m.label.defaultMessage }))}
                    />
                  </div>
                )}
              </div>
              <div className="input-text-identity-company">
                <InputText
                  displayErrorInstantly
                  field={registrationNumber}
                  id="identity-registration-number"
                  label={{
                    ...messages.genericRegistartionNumber,
                    defaultMessage: 'Company’s Registration Number',
                  }}
                  labelClasses=""
                  max={30}
                  min={0}
                  type="text"
                />

                {country.value === 'CA' && (
                  <>
                    <InputText
                      displayErrorInstantly
                      field={gstTaxId}
                      id="identity-tax-id"
                      label={{
                        ...messages.genericGstTaxId,
                        defaultMessage: 'GST / HST Tax ID (Same as business number)',
                      }}
                      labelClasses=""
                      max={25}
                      min={0}
                      type="text"
                    />
                    <InputText
                      displayErrorInstantly
                      field={pstTaxId}
                      id="identity-pst-tax-id"
                      label={{
                        ...messages.genericPstTaxId,
                        defaultMessage: 'PST Tax ID (if applicable)',
                      }}
                      labelClasses=""
                      max={20}
                      min={0}
                      type="text"
                    />
                  </>
                )}
              </div>
            </div>
          )}
          {profileIdentity.value && profileIdentity.value?.toUpperCase() !== AffiliateTypeEnum.LEGACY ? (
            profileIdentity.value?.toUpperCase() === AffiliateTypeEnum.COMPANY ? (
              <>
                <button
                  className="waves-effect waves-light btn bg-primary-color"
                  disabled={profileIdentity.value?.toUpperCase() === AffiliateTypeEnum.COMPANY && invalid}
                  onClick={handleSubmitCompany}
                >
                  Save Changes
                </button>
                {hasToCompleteBillingInfo ? (
                  <MessageInfo
                    messageWithInternalLink={[
                      'Please ',
                      <Link key="/profile/billing-infos" to="/profile/billing-infos">
                        complete your billing infos
                      </Link>,
                      ' to finalize your account setup.',
                    ]}
                  />
                ) : null}
              </>
            ) : (
              <>
                {hasToShowButtonValidation ? (
                  <button
                    className="waves-effect waves-light btn bg-primary-color"
                    onClick={() => {
                      handleJumioClick();
                      Gtm.event('profile - identity', 'Click', `Verify your identity - Individual`);
                    }}
                  >
                    Verify your identity
                  </button>
                ) : null}
                {validationStatusName !== UNVERIFIED && (
                  <MessageInfo
                    className={validationStatusName?.toLowerCase()}
                    message={messageInfo.identityStatusMessage}
                    messageWithInternalLink={messageInfo.messageWithInternalLink}
                    onClick={messageInfo.onClick}
                    restart={messageInfo.restart}
                  />
                )}
              </>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default connect((state) => ({
  countries: state.application.lists.countries,
  affiliateValidationStatus: state.profile.data.affValidationStatus,
  beneficiaryInfos: state?.profile?.data?.affiliateBeneficiary,
  listValidationStatus: state.application.lists.validationStatus,
  listAffiliateTypes: state.application.lists.affiliateTypes,
  accountType:
    state.profile.data.customProfile.affiliate.affiliate_type?.type || state.profile.data.customProfile.affiliate.affiliate_type,
  affCompany: state.profile.data.affCompany,
  affId: state.profile.data.affInfos.id,
  affInfos: state.profile.data.affInfos,
  affiliate: state.profile.data.customProfile.affiliate,
  payoutTotal: state.payoutTotal.payoutTotal,
  isFetchingPayoutTotal: state.payoutTotal.isFetchingPayoutTotal,
}))(
  reduxForm(
    {
      form: 'profileIdentityInfos',
      touchOnChange: false,
      touchOnBlur: false,
      fields: ['company_name', 'profileIdentity', 'country', 'state', 'registrationNumber', 'gstTaxId', 'pstTaxId', 'province'],

      validate,
    },
    (state) => ({
      initialValues: {
        profileIdentity: state?.profile?.data?.customProfile?.affiliate?.affiliate_type?.type,
        company_name: state?.profile?.data?.affCompany?.name,
        country: state?.profile?.data?.affCompany?.registration_country_code,
        registrationNumber: state?.profile?.data?.affCompany?.registration_number,
        gstTaxId: state?.profile?.data?.affCompany?.tax_id_gst_hst,
        pstTaxId: state?.profile?.data?.affCompany?.tax_id_pst,
        province:
          state?.profile?.data?.affCompany?.registration_country_code === 'CA'
            ? state?.profile?.data?.affCompany?.registration_subdivision_code
            : null,
        state:
          state?.profile?.data?.affCompany?.registration_country_code === 'US'
            ? state?.profile?.data?.affCompany?.registration_subdivision_code
            : null,
        validationStatusId: state.profile.data.affValidationStatus?.validation_status_id,
        referrals: state.referrals.referrals,
        referralsSettings: state.referrals.referralsSettings,
        referralsTimeStamp: state.referrals.referralsTimeStamp,
      },
    })
  )(Identity)
);
