import PropTypes from 'prop-types';
import _ from 'lodash';
import { reduxForm, initialize, change } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Gtm } from '../../utils/gtm';

import messages from '../../i18n/base-en.js';

import StatisticsFilters from './StatisticsFilters.react';
import { DateRange, SearchTags } from '../../components/reusables';

import { removeStatisticsSearchFiltersTag, removeAllStatisticsFilters } from '../../actions/statistics-actions';
import { getLastSevenPeriod } from '../../utils/helpers';
import { openModal } from '../../components/Modal/modal-actions/modal-actions';
import { useEffect } from 'react';

const { dateStart, dateEnd } = getLastSevenPeriod();

const filtersFields = [
  'presets',
  'startDateFilter',
  'endDateFilter',
  'timezone',
  'vertical',
  'brand',
  'offer',
  'offerUrl',
  'goals',
  'country',
  'niche',
  'browser',
  'payoutType',
  'subId1',
  'subId2',
  'subId3',
  'subId4',
  'subId5',
  'source',
];

const StatisticsFiltersContainer = ({
  browsersList,
  categories,
  countries,
  dispatch,
  fields,
  filters,
  goalsList,
  handleSubmit,
  offersIsFetching,
  offersList,
  offersUrlList,
  requestReload,
  statisticsSelectedFilters,
  values,
}) => {
  const handleRemoveFilterTag = (tagId, tagValue, tagCompare) => {
    const newValues = { ...values };
    newValues[tagId] = _.without(newValues[tagId], tagValue);
    dispatch(removeStatisticsSearchFiltersTag(tagId, tagValue, tagCompare));
    dispatch(change('statisticsFilters', tagId, newValues[tagId]));
    setTimeout(() => {
      dispatch(
        initialize(
          'statisticsFilters',
          {
            ...newValues,
          },
          filtersFields
        )
      );
    }, 150);
  };

  const handleRemoveAllStatisticsFilters = () => {
    dispatch(removeAllStatisticsFilters());
    dispatch(
      initialize(
        'statisticsFilters',
        {
          endDateFilter: fields.endDateFilter.value,
          startDateFilter: fields.startDateFilter.value,
          presets: fields.presets.value,
        },
        filtersFields
      )
    );
  };

  const openAddFilterModal = (e) => {
    e.preventDefault();
    dispatch(
      initialize(
        'statisticsAddFilters',
        {
          ...statisticsSelectedFilters,
        },
        [
          'filterSubId1',
          'filterSubId2',
          'filterSubId3',
          'filterSubId4',
          'filterSubId5',
          'filterSource',
          'filterBrowser',
          'filterCountry',
          'filterPayoutType',
        ]
      )
    );
    Gtm.event('statistics', 'Click', 'more filters');
    dispatch(
      openModal({
        name: 'StatisticsAddFiltersModal',
        modalProps: {
          className: 'stats-add-filter-modal',
        },
      })
    );
  };

  useEffect(() => {
    handleRemoveAllStatisticsFilters();
  }, []);

  return (
    <div className="search-component" id="statistics-filters">
      <form action="#" method="post" onSubmit={handleSubmit}>
        <div className="filters dateRange">
          <div className="row">
            <div className="col-lg-7 col-md-9 col-sm-12 col-xs-12">
              <DateRange
                dispatch={dispatch}
                endField={fields.endDateFilter}
                presets={fields.presets}
                startField={fields.startDateFilter}
              />
            </div>
            <div className="col-lg-5 col-md-3">
              <button className="addFiltersModalLink primary-color" onClick={openAddFilterModal}>
                <i className="material-icons">add</i>
                <FormattedMessage {...messages.genericTextMoreFilters} />
              </button>
            </div>
          </div>
        </div>

        <div className="filters allFilters">
          <StatisticsFilters
            browsersList={browsersList}
            categories={categories}
            countries={countries}
            dispatch={dispatch}
            fields={fields}
            filtersAreDisabled={offersIsFetching}
            goalsList={goalsList}
            offersList={offersList}
            offersUrlList={offersUrlList}
            requestReload={requestReload}
            statisticsSelectedFilters={statisticsSelectedFilters}
            values={values}
          />
        </div>
      </form>

      {Object.keys(filters.tags).some((filter) => filters.tags[filter].length > 0) && (
        <div className="row tags-container">
          <div className="col-md-12">
            <div className="pull-left clearfix">
              <SearchTags handleRemoveTag={handleRemoveFilterTag} tagClassNames={['filters-tag']} values={filters.tags} />
            </div>

            <a className="search-tag clear-filters" href="#" onClick={handleRemoveAllStatisticsFilters}>
              <i className="material-icons">delete</i>
              <FormattedMessage {...messages.genericTextResetAllFilters} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

StatisticsFiltersContainer.propTypes = {
  browsersList: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  goalsList: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  offersIsFetching: PropTypes.bool.isRequired,
  offersList: PropTypes.object.isRequired,
  offersUrlList: PropTypes.array.isRequired,
  queries: PropTypes.object.isRequired,
  requestReload: PropTypes.func.isRequired,
  statisticsDateEnd: PropTypes.string.isRequired,
  statisticsDateStart: PropTypes.string.isRequired,
  statisticsSelectedFilters: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default injectIntl(
  reduxForm(
    {
      form: 'statisticsFilters',
      fields: filtersFields,
      destroyOnUnmount: false,
    },
    (state) => ({
      initialValues: {
        startDateFilter: dateStart,
        endDateFilter: dateEnd,
        presets: 'Last 7 Days',
      },
    })
  )(StatisticsFiltersContainer)
);
