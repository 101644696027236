import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Link } from 'react-router';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { getValues } from 'redux-form';
import AddToolBanner from './AddToolBanner.react';
import messages from '../../i18n/base-en.js';
import {
  CodePreview,
  CopyValueWidget,
  Container,
  CreativesCardsContainer,
  CreativesEmailFilters,
  CreativesHtmlFilters,
  CreativesTable,
  Pagination,
  Scroller,
  SelectedCreativesTable,
  ViewMode,
} from '../reusables';

import {
  fetchOfferOverlay,
  fetchOfferPopCode,
  fetchTrackingLink,
  sendCreativesByEmail,
} from '../../pages/offer/Offer-Actions/Offer-Actions';
import { changeOffersCreativesViewMode, setActionMessage } from '../Application/actions/application-actions';
import {
  fetchCreatives,
  fetchCreativesSizes,
  fetchCreativeCode,
  resetCreativesTrending,
  resetCreatives,
  resetCreativesSizes,
} from '../../actions/creatives-actions';
import { clearObject } from '../../utils/helpers';
import request from '../../utils/Req';
import HandleAPIError from '../../utils/handleAPIError';
import { Gtm } from '../../utils/gtm';
import intercom from '../../utils/intercom';

import CreativesPopCode from './creatives/CreativesPopCode.react';

import config from '../../config';
import consts from '../../utils/consts.js';
import CreativeAdTools from './creatives/CreativesAdTools';
import CreativesBanners from './creatives/CreativesBanners';
import { connect } from 'react-redux';
import { USE_PROVEN_FLOW } from './creatives/banners/values.js';
import SkinConfigurationsUtils from '../../utils/SkinConfigurationsUtils.js';
import Skeleton from './Skeleton/Skeleton';
const { API_URL, VERTICAL_ADULTPAYSITE_ID, CHAT_HEAD_URL } = config;
const { ADTOOL_ID_CHATHEAD } = consts;

const generateCreativeCode = ({ affId, affSub, creativeCode, fileId, offerId, trackingLink, uriTrackingLink }) =>
  creativeCode
    ? creativeCode
        .replace(/\{aff_id\}/g, affId)
        .replace(/\{aff_sub\}/g, affSub || '')
        .replace(/\{file_id\}/g, fileId)
        .replace(/\{offer_id\}/g, offerId)
        .replace(/\{tracking_link\}/g, trackingLink)
        .replace(/\{etracking_link\}/g, uriTrackingLink)
    : '';

const generateHtmlIframe = (creativeCode) =>
  `javascript:document.write('${creativeCode.replace(/"/g, '\\"').replace(/'/g, "\\'")}')`;

const Creatives = (props) => {
  const {
    affId,
    creatives,
    creativesIsFetching,
    creativeLink,
    creativesSizes,
    campaignLink,
    creativesSizesIsFetching,
    creativesSearchForm,
    defaultTab,
    dispatch,
    displaySubHeaders,
    fields,
    intl,
    isAutoOptimized,
    isReferrals,
    mailingOfferId,
    offerId,
    offerOverlay,
    postitialLandingIds,
    prestitialLandingIds,
    offerLandingPages,
    offerPopCode,
    offerThumbnail,
    offerVerticals,
    offersCreativesViewMode,
    onSmartlinkCopy,
    showEmails,
    skinConfigurations,
    tabs,
    trackingLink,
    values: { aff_sub },
    strippedValues,
    isSmartlinkDatingOrCam,
  } = props;

  const [popCodeTrackingLink, setPopCodeTrackingLink] = useState('');

  const [type, setType] = useState({
    banner: {
      name: 'image banner',
      query: {},
      reloadCreatives: true,
    },
    trending: {
      name: 'image banner',
      query: {},
      reloadCreatives: true,
    },
    content: {
      name: 'file',
      query: {
        filters: {
          content: '1',
          size: undefined,
        },
        page: 1,
        limit: undefined,
      },
      reloadCreatives: true,
    },
    email: {
      name: 'email creative',
      query: {},
      reloadCreatives: true,
    },
    html: {
      name: 'html ad',
      query: {
        filters: {
          pop: '0',
        },
      },
      reloadCreatives: true,
    },
    iframe: {
      name: 'html ad',
      query: {
        filters: {
          iframe: '1',
          size: undefined,
        },
        page: 1,
        limit: undefined,
      },
      reloadCreatives: true,
    },
    link: {
      name: 'link',
      query: {},
      reloadCreatives: false,
    },
    chatHead: {
      name: 'chatHead',
      query: {},
      reloadCreatives: true,
    },
    undefined: {
      name: 'none',
      query: {},
      reloadCreatives: false,
    },
  });

  useEffect(() => {
    const { dispatch } = props;

    dispatch(resetCreativesTrending());
    dispatch(resetCreatives());
    dispatch(resetCreativesSizes());
  }, [offerId]);

  const [state, setState] = useState({
    selectedCreatives: [],
    selectedTab: undefined,
    creativePreview: {
      creative: {},
      left: 0,
      show: false,
      top: 0,
      width: 0,
      height: 0,
    },
    settings: {
      limit: 10,
      page: 1,
    },
  });

  const creativesLoading =
    creativesIsFetching || creativesSizesIsFetching || offerOverlay?.isFetching || offerPopCode?.isFetching;

  const {
    creativePreview: { creative, height, left, show, top, width },
    selectedCreatives,
    selectedTab,
    settings,
  } = state;

  const [creativesExist, setCreativesExist] = useState({
    loading: true,
    html: false,
    iframe: false,
  });

  const [adToolHeight, setAdToolHeight] = useState('auto');

  const settingsRef = useRef(undefined);
  const adToolAreaRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!creativesLoading) {
      timeoutRef.current = setTimeout(() => {
        const height = `${adToolAreaRef?.current?.offsetHeight}px`;
        setAdToolHeight(height);
      }, 50);
    }
    () => clearTimeout(timeoutRef.current);
  }, [creativesLoading, creativesExist.loading]);

  useEffect(() => {
    const removeListener = () => window.removeEventListener('resize', resize);
    const resize = () => setAdToolHeight(`${adToolAreaRef?.current?.offsetHeight}px`);
    removeListener();
    window.addEventListener('resize', resize);
    () => removeListener();
  }, []);

  const onIframeSizeChange = (size) => {
    const { offerId } = props;
    const { selectedTab, settings } = state;

    const newTypes = {
      ...type,
    };

    newTypes[selectedTab].query.filters.size = size;

    setType(newTypes);
    fetchInfos(offerId, selectedTab, settings);
  };

  useEffect(() => {
    selectTab(defaultTab, true);
  }, [defaultTab]);

  useEffect(() => {
    if (selectedTab === 'chatHead') {
      selectTab(defaultTab, true);
    }
  }, [isSmartlinkDatingOrCam]);

  const getDownloadUrl = () => {
    const { offerId } = props;
    const { selectedCreatives } = state;

    const id = selectedCreatives.map((creative) => creative.id);

    const downloadAllRequest = request.get(`${API_URL}/creatives/download`).query({
      offerId,
      search: JSON.stringify({
        filters: { id },
        page: 1,
        limit: 100000,
      }),
    });
    return `${downloadAllRequest.url}?${downloadAllRequest._query}`;
  };

  const sortCreativesSizes = (creativesSizes) =>
    _.uniq([
      ...['300x250', '320x50', '728x90'].filter((topSize) => creativesSizes.some((creativeSize) => topSize === creativeSize)),
      ...creativesSizes,
    ]);

  const handleOthersTabs = async (offerId, selectedTab, settings, data = {}, mainTabClick) => {
    const { dispatch } = props;
    const { name, query, reloadCreatives } = type[selectedTab];

    if (selectedTab === 'trending' && USE_PROVEN_FLOW() && mainTabClick) {
      await dispatch(
        fetchCreatives({
          offerId,
          search: JSON.stringify({
            limit: 10000,
            page: 1,
            filters: {
              type: name,
            },
          }),
          fetchTrendingBanners: true,
        })
      );
    }

    if (reloadCreatives) {
      dispatch(
        fetchCreativesSizes({
          offerId,
          type: name,
        })
      ).then((res) => {
        const search = {
          ...settings,
          ...query,
          filters: {
            type: name,
            ...data,
            size: data.size ? data.size : sortCreativesSizes(res.sizes)[0],
            ...query.filters,
          },
        };
        dispatch(
          fetchCreatives({
            offerId,
            search: JSON.stringify(search),
          })
        );
      });
    }
  };

  useEffect(async () => {
    const tabs = ['html', 'iframe'];
    const settings = { limit: 1, page: 1 };

    const queries = tabs.map((selectedTab) => {
      const { name, query } = type[selectedTab];

      const search = {
        ...settings,
        ...query,
        filters: {
          type: name,
          ...query.filters,
        },
      };

      return JSON.stringify(search);
    });

    const promises = [];

    queries.forEach((search) =>
      promises.push(
        fetchCreatives({
          offerId,
          search,
        })()
      )
    );

    let htmlCreativesExist = false;
    let iframeCreativesExist = false;

    try {
      const [htmlCreatives, iframeCreatives] = (await Promise.all(promises)) || [];

      htmlCreativesExist = htmlCreatives?.creatives?.length ? true : false;
      iframeCreativesExist = iframeCreatives?.creatives?.length ? true : false;
    } catch (e) {
      console.error(e);
    }

    setCreativesExist({
      loading: false,
      html: htmlCreativesExist,
      iframe: iframeCreativesExist,
    });
  }, [offerId]);

  const handleOverlayTab = () => {
    const { dispatch, offerOverlay } = props;
    if (offerOverlay && !offerOverlay.isFetching) {
      dispatch(fetchOfferOverlay());
    }
  };

  const handlePopCodeTab = () => {
    const { dispatch, offerPopCode } = props;
    if (offerPopCode && !offerPopCode.isFetching) {
      dispatch(fetchOfferPopCode());
    }
  };

  const fetchInfos = (offerId, selectedTab, settings, data = {}, mainTabClick) => {
    switch (selectedTab) {
      case 'pop':
        handlePopCodeTab();
        break;
      case 'prestitial':
        handleOverlayTab();
        break;
      case 'postitial':
        handleOverlayTab();
        break;
      default:
        handleOthersTabs(offerId, selectedTab, settings, data, mainTabClick);
        break;
    }
  };

  useMemo(() => {
    //previous UNSAFE_componentWillReceiveProps
    if (selectedTab) {
      const filtersData = getValues(creativesSearchForm) || {};
      fetchInfos(offerId, selectedTab, settings, filtersData);
    }
  }, [offerId]);

  useMemo(() => {
    //previous UNSAFE_componentWillReceiveProps
    if (settingsRef.current && !_.isEqual(settingsRef.current, settings)) {
      settingsRef.current = settings;
      const filtersData = getValues(creativesSearchForm) || {};
      fetchInfos(offerId, selectedTab, settings, filtersData);
    } else if (!settingsRef.current) {
      settingsRef.current = settings;
    }
  }, [settings]);

  const displayLink = (creative) => {
    const {
      dispatch,
      location: { pathname },
      offerId,
      showEmails,
      showEmailPopup,
      values,
    } = props;

    intercom.trackEvent('Creative offers', {
      offer_id: offerId,
    });
    dispatch(
      fetchTrackingLink(
        offerId,
        clearObject({
          ...values,
          file_id: creative.id,
          offer_id: undefined,
        })
      )
    )
      .then((results) => {
        dispatch(
          fetchCreativeCode({
            creativeId: creative.id,
            offerId,
            params: JSON.stringify({
              tracking_url: (values.url_id === 'optimized' ? campaignLink : creativeLink || results.click_url).concat(
                '&aff_sub4=AT_0002'
              ),
              impression_pixel: results.impression_pixel,
            }),
          })
        )
          .then((res) => {
            if (creatives.creatives[0].type === 'image banner') {
              navigator.clipboard.writeText(res.CreativeCode);
              const text = res.CreativeCode.length > 170 ? `${res.CreativeCode.substr(0, 170)}...` : res.CreativeCode;
              dispatch(
                setActionMessage(
                  'success',
                  {
                    text: intl.formatMessage(messages.genericTextTextCopied, { text }),
                  },
                  3000
                )
              );
            } else if (typeof showEmailPopup === 'function' && showEmails) {
              showEmailPopup(res.CreativeCode);
            } else {
              dispatch(setActionMessage('copy', { text: res.CreativeCode }, 900000));
            }
          })
          .catch((err) => new HandleAPIError(err, dispatch).handleAll());
      })
      .catch(() => {});
  };

  const displayUrl = (creative) => {
    const { dispatch, showEmailPopup } = props;
    if (creative.type === 'image banner') {
      navigator.clipboard.writeText(creative.url);
      const text = creative.url.length > 170 ? `${creative.url.substr(0, 170)}...` : creative.url;
      dispatch(
        setActionMessage(
          'success',
          {
            text: intl.formatMessage(messages.genericTextTextCopied, { text }),
          },
          3000
        )
      );
    } else if (typeof showEmailPopup === 'function') {
      showEmailPopup();
    } else {
      dispatch(setActionMessage('copy', { text: creative.url }, 900000));
    }
  };

  const handlePageChange = (page) => {
    const { settings } = state;
    setState((state) => ({
      ...state,
      settings: {
        ...settings,
        page,
      },
    }));
  };

  const handleRowsChange = (limit, page) => {
    const { settings } = state;
    setState((state) => ({
      ...state,
      settings: {
        ...settings,
        page,
        limit,
      },
    }));
  };

  const handleSelectCreative = (creative, selected) => {
    const { selectedCreatives } = state;

    if (selected) {
      const selectedIndex = selectedCreatives.map((selectedCreative) => selectedCreative.id).indexOf(creative.id);
      setState((state) => ({
        ...state,
        selectedCreatives: [...selectedCreatives.slice(0, selectedIndex), ...selectedCreatives.slice(selectedIndex + 1)],
      }));
    } else {
      setState((state) => ({
        ...state,
        selectedCreatives: [...selectedCreatives, creative],
      }));
    }
  };

  const handleSearchSubmit = (data) => {
    const { offerId } = props;
    const { selectedTab, settings } = state;
    fetchInfos(offerId, selectedTab, settings, data);
  };

  const handleBannersSubmit = ({ filters: data }) => {
    const { offerId } = props;
    const { selectedTab, settings } = state;
    fetchInfos(offerId, selectedTab, settings, data);
  };

  const handleViewModeChange = (viewMode) => {
    const { dispatch } = props;

    dispatch(changeOffersCreativesViewMode(viewMode));
  };

  const isSelected = (id) => {
    const { selectedCreatives } = state;

    return selectedCreatives.some((selectedCreative) => selectedCreative.id === id);
  };

  const selectTab = (tab, isDefault = false) => {
    if (creativesLoading) {
      return;
    }

    const { offerId, tabs } = props;
    const { selectedTab, settings } = state;

    if (selectedTab !== tab) {
      setState((state) => ({
        ...state,
        selectedTab: tab,
      }));

      if (!isDefault && tabs[tab] && tabs[tab].eventLabel) {
        let eventLabel;
        let eventCategory;
        if (window.location.pathname === '/smartlink') {
          eventLabel = tabs[tab].eventLabel.split('Console-Smartlink-')[1];
          eventCategory = 'dating smartlink';
        } else {
          eventLabel = tabs[tab].eventLabel.split('Console-Offer-')[1];
          eventCategory = 'offers';
        }
        const tabLabel = eventLabel.split('-Tab')[0];
        if (tabLabel === 'HTML') {
          Gtm.event(eventCategory, 'Click', 'html ads');
        } else if (tabLabel === 'Pop') {
          Gtm.event(eventCategory, 'Click', 'PopCode');
        } else {
          Gtm.event(eventCategory, 'Click', tabLabel);
        }
      }
    }

    //CREATIVES ARE ALREADY FETCHED
    if (tab === 'trending' && creativesSizes.length && !tabIsAvailable('html')) {
      return;
    }

    fetchInfos(offerId, tab, settings, {}, true);
  };

  const showPreview = (creative, e) => {
    const offset = 20;
    let width = parseInt(creative.width, 10);
    let height = parseInt(creative.height, 10);
    let top = 0;
    let left = 0;
    let samePos = true;

    if (creative.type === 'email creative') {
      width = 600;
      height = (600 * 9) / 16;
    }

    if (e) {
      left = e.clientX + offset;
      if (left + width > window.innerWidth) {
        left = e.clientX - width - offset;
      }
      if (width > left) {
        left = 0;
      }

      top = e.clientY + offset;
      if (top + height > window.innerHeight) {
        top = e.clientY - height - offset;
      }
      if (height > top) {
        top = 0;
      }

      samePos = false;
    }

    setState((state) => {
      const creativePreview = state.creativePreview;

      if (samePos) {
        top = state.creativePreview.top;
        left = state.creativePreview.left;
      }

      return {
        ...state,
        creativePreview: {
          ...creativePreview,
          creative,
          top,
          left,
          width,
          height,
        },
      };
    });

    togglePreview(true);
  };

  const hidePreview = () => {
    togglePreview(false);
  };

  const togglePreview = (show) => {
    const { creativePreview } = state;

    setState((state) => ({
      ...state,
      creativePreview: {
        ...creativePreview,
        show,
      },
    }));
  };

  const sendSelectedCreativesEmail = () => {
    const { userEmail, values, dispatch, offerId, intl } = props;
    const { selectedCreatives } = state;

    dispatch(sendCreativesByEmail(offerId, userEmail, selectedCreatives, values)).then(() => {
      dispatch(
        setActionMessage(
          'success',
          {
            text: intl.formatMessage(messages.offerCreativesWasSent),
          },
          3000
        )
      );
    });
  };

  const selectedCreativesNumber = selectedCreatives.length;

  const containerClasses = [
    offersCreativesViewMode === 'cards' ? 'offersCreativesCards' : undefined,
    'col-md-12',
    'z-depth-2',
  ].join(' ');

  const getTabClassNames = (tabName) => (tabName === selectedTab ? 'active' : '');

  const injectDefaultQueryParam = ({ url, paramName, paramDefaultValue }) => {
    try {
      const urlTmp = new URL(url);
      const params = new URLSearchParams(urlTmp.search);
      //only apply aff_sub if the affiliate did not set it in their options
      if (!params.get(paramName)) {
        urlTmp.searchParams.set(paramName, paramDefaultValue);
      }
      return urlTmp.href;
    } catch (error) {
      return url;
    }
  };

  const creativesContent =
    creatives.creatives.length > 0 ? (
      <div>
        <div className="selected-creatives-title hidden-xlg">
          <h2 className="clearfix">
            <div>
              {selectedCreativesNumber} <FormattedMessage {...messages.offerCreativesSelected} />
            </div>
            {selectedCreativesNumber > 0 && (
              <span className="send-selected-creatives" onClick={sendSelectedCreativesEmail}>
                <span>
                  <i className="material-icons primary-color">mail_outline</i>
                  <FormattedMessage {...messages.offerSendSelection} />
                </span>
              </span>
            )}
          </h2>
        </div>
        <div className="row">
          <Container className={containerClasses} loading={creativesLoading}>
            <div className="clearfix">
              <ViewMode
                classNames={['pull-left', 'visible-xlg', 'viewmode']}
                currentViewMode={offersCreativesViewMode}
                handleViewModeChange={handleViewModeChange}
              />
              <Pagination
                choices={[10, 20, 50, 100]}
                className="pull-right hidden-xs hidden-sm"
                currentPage={settings.page}
                handlePageChange={handlePageChange}
                handleRowsChange={handleRowsChange}
                pageCount={creatives.pageCount}
                rowsPerPage={parseInt(settings.limit, 10)}
                selectId="rowsPerPage"
                totalResults={creatives.count}
              />
            </div>
            {!creativesLoading &&
              (offersCreativesViewMode === 'cards' ? (
                <CreativesCardsContainer
                  creatives={creatives.creatives}
                  displayLink={displayLink}
                  displayUrl={displayUrl}
                  generateHtmlIframe={generateHtmlIframe}
                  handleSelectCreative={handleSelectCreative}
                  isSelected={isSelected}
                  offerThumbnail={offerThumbnail}
                />
              ) : (
                <CreativesTable
                  creatives={creatives.creatives}
                  dispatch={dispatch}
                  displayLink={displayLink}
                  displayUrl={displayUrl}
                  handleSelectCreative={handleSelectCreative}
                  hidePreview={hidePreview}
                  isSelected={isSelected}
                  showPreview={showPreview}
                />
              ))}
            <div className="clearfix">
              <Pagination
                choices={[10, 20, 50, 100]}
                className="pull-right hidden-xlg hidden-lg hidden-md"
                currentPage={settings.page}
                handlePageChange={handlePageChange}
                handleRowsChange={handleRowsChange}
                pageCount={creatives.pageCount}
                rowsPerPage={parseInt(settings.limit, 10)}
                selectId="rowsPerPage"
                totalResults={creatives.count}
              />
            </div>
          </Container>
          {!showEmails && selectedTab === 'email' && (
            <Container className={containerClasses}>
              <div className="spacing-text">
                <FormattedMessage {...messages.offerNoEmailTemplates} />
              </div>
            </Container>
          )}
        </div>
        {selectedCreativesNumber > 0 && (
          <div className="row visible-xlg">
            <div className="selected-creatives-title">
              <h2>
                {selectedCreativesNumber} <FormattedMessage {...messages.offerCreativesSelected} />
                <a
                  className="pull-right send-selected-creatives"
                  href={getDownloadUrl()}
                  onClick={() => {
                    Gtm.event(
                      `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
                      'Click',
                      'download'
                    );
                  }}
                >
                  <i className="material-icons primary-color">file_download</i>
                  <FormattedMessage {...messages.offerDownloadAll} />
                </a>
                {selectedCreativesNumber > 0 && (
                  <span
                    className="pull-right send-selected-creatives"
                    onClick={() => {
                      sendSelectedCreativesEmail();

                      Gtm.event(
                        `${window.location.pathname === '/smartlink' ? 'dating smartlink' : 'offers'}`,
                        'Click',
                        'send selection'
                      );
                    }}
                  >
                    <span>
                      <i className="material-icons primary-color">mail_outline</i>
                      <FormattedMessage {...messages.offerSendSelection} />
                    </span>
                  </span>
                )}
              </h2>
            </div>
            <Container className="col-md-12 z-depth-2">
              <SelectedCreativesTable
                affId={affId}
                creatives={selectedCreatives}
                dispatch={dispatch}
                displayLink={displayLink}
                displayUrl={displayUrl}
                handleSelectCreative={handleSelectCreative}
                hidePreview={hidePreview}
                isSelected={isSelected}
                showPreview={showPreview}
              />
            </Container>
          </div>
        )}
        <div
          className="creatives-preview-container"
          onMouseEnter={() => showPreview(creative)}
          onMouseLeave={hidePreview}
          style={{ left, top }}
        >
          {show ? (
            <div className="creatives-preview z-depth-2">
              <div className="creatives-preview-close visible-xs visible-sm visible-md z-depth-2" onClick={hidePreview}>
                <i className="material-icons">close</i>
              </div>
              {creative.code ? (
                <iframe
                  scrolling="no"
                  src={generateHtmlIframe(creative.code)}
                  style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    border: 'none',
                    margin: 0,
                  }}
                />
              ) : null}
              {creative.thumbnail ? (
                <img alt={creative.display} height={creative.height} src={creative.url} width={creative.width} />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    ) : (
      ''
    );

  const content = {
    undefined: {
      search: null,
      content: (
        <div className="fullWidth">
          <Container className="col-md-12 z-depth-2 select-creative-type">
            <div>
              <div className="icon primary-color">
                <i className="material-icons">arrow_upward</i>
              </div>
              <div className="text">
                <FormattedMessage {...messages.offerPleaseSelectAnAdTool} />
              </div>
            </div>
          </Container>
        </div>
      ),
    },
    trending: {
      search: null,
      content: (
        <>
          {creativesSizes.length ? (
            <CreativesBanners
              creatives={creatives.creatives}
              creativesLoading={creativesLoading}
              creativesSizes={sortCreativesSizes(creativesSizes)}
              displayLink={displayLink}
              displayUrl={displayUrl}
              generateHtmlIframe={generateHtmlIframe}
              getDownloadUrl={getDownloadUrl}
              handleBannersSubmit={handleBannersSubmit}
              handlePageChange={handlePageChange}
              handleRowsChange={handleRowsChange}
              handleSelectCreative={handleSelectCreative}
              isSelected={isSelected}
              offerThumbnail={offerThumbnail}
              pageCount={creatives.pageCount}
              selectedCreatives={selectedCreatives}
              sendSelectedCreativesEmail={sendSelectedCreativesEmail}
              settings={settings}
              totalResults={creatives.count}
            />
          ) : !creativesLoading ? (
            <Container className="col-md-12 z-depth-2">
              <AddToolBanner />
            </Container>
          ) : (
            <Skeleton height="700px" width="100%" />
          )}
        </>
      ),
    },
    html: {
      search: (
        <div className="fullWidth">
          <Container
            className="col-md-12 z-depth-2"
            icon="filter_list"
            title={displaySubHeaders ? <FormattedMessage {...messages.offerHtmlAdsFilters} /> : ''}
          >
            {creatives.creatives.length > 0 && !creativesLoading ? (
              <CreativesHtmlFilters
                creativesSizes={sortCreativesSizes(creativesSizes)}
                isLoading={creativesLoading}
                onSubmit={handleSearchSubmit}
              />
            ) : !creativesLoading ? (
              <AddToolBanner />
            ) : (
              <Skeleton height="700px" width="100%" />
            )}
          </Container>
        </div>
      ),
      content: creativesContent,
    },
    pop: {
      search: null,
      content: !creativesLoading ? (
        <CreativesPopCode
          creativeCode={
            offerPopCode.code
              ? generateCreativeCode({
                  affId,
                  affSub: aff_sub,
                  creativeCode: offerPopCode.code,
                  fileId: offerPopCode.id,
                  offerId,
                  trackingLink: popCodeTrackingLink,
                  uriTrackingLink: encodeURIComponent(trackingLink),
                })
              : ''
          }
          dispatch={dispatch}
          isLoading={offerPopCode.isFetching}
          offerFileId={offerPopCode.id}
        />
      ) : !creativesLoading ? (
        <AddToolBanner />
      ) : (
        <Skeleton height="700px" width="100%" />
      ),
    },
    postitial: {
      search: null,
      content:
        offerOverlay.code && !creativesLoading ? (
          <CreativeAdTools
            adToolType="postitial"
            creativeCode={generateCreativeCode({
              affId,
              affSub: aff_sub,
              creativeCode: offerOverlay.code,
              fileId: offerOverlay.id,
              offerId,
              trackingLink,
              uriTrackingLink: encodeURIComponent(trackingLink),
            })}
            dispatch={dispatch}
            fields={fields}
            isAutoOptimized={isAutoOptimized}
            isReferrals={isReferrals}
            landingPagesIds={postitialLandingIds}
            offerId={offerId}
            offerLandingPages={offerLandingPages}
            skinConfigurations={skinConfigurations}
            strippedValues={strippedValues}
          />
        ) : !offerOverlay.isFetching ? (
          <AddToolBanner />
        ) : (
          <Skeleton height="700px" width="100%" />
        ),
    },
    prestitial: {
      search: null,
      content:
        offerOverlay.code && !creativesLoading ? (
          <CreativeAdTools
            adToolType="prestitial"
            creativeCode={generateCreativeCode({
              affId,
              affSub: aff_sub,
              creativeCode: offerOverlay.code,
              fileId: offerOverlay.id,
              offerId,
              trackingLink,
              uriTrackingLink: encodeURIComponent(trackingLink),
            })}
            dispatch={dispatch}
            landingPagesIds={prestitialLandingIds}
            offerId={offerId}
            offerLandingPages={offerLandingPages}
            strippedValues={strippedValues}
          />
        ) : !offerOverlay.isFetching ? (
          <AddToolBanner />
        ) : (
          <Skeleton height="700px" width="100%" />
        ),
    },
    email: {
      search:
        showEmails && !creativesLoading ? (
          <div className="fullWidth">
            <Container
              className="col-md-12 z-depth-2"
              icon="filter_list"
              title={<FormattedMessage {...messages.offerTemplatesFilters} />}
            >
              <CreativesEmailFilters isLoading={creativesLoading} onSubmit={handleSearchSubmit} />
            </Container>
          </div>
        ) : !creativesLoading ? (
          <AddToolBanner />
        ) : (
          <Skeleton height="700px" width="100%" />
        ),
      content: creativesContent,
    },
    link: {
      search: null,
      content: !creativesLoading ? (
        <div className="fullWidth">
          <div className="creatives-tracking-link">
            <CopyValueWidget
              dispatch={dispatch}
              id="smart-link-trackinglink"
              label={messages.smartLinkYourLink}
              onCopy={onSmartlinkCopy}
              value={trackingLink}
            />
          </div>
        </div>
      ) : (
        <Skeleton height="700px" width="100%" />
      ),
    },
    iframe: {
      search: null,
      content: !creativesLoading ? (
        <div className="fullWidth">
          <CodePreview
            affId={affId}
            affSub={aff_sub}
            creativesData={!creativesLoading ? creatives.creatives : null}
            creativesIsFetching={creativesLoading}
            creativesSizes={creativesSizes}
            dispatch={dispatch}
            formatMessage={intl.formatMessage(messages.offerNoIframeCode)}
            generateCreativeCode={generateCreativeCode}
            offerCopyIframeCode={messages.offerCopyIframeCode}
            offerId={offerId}
            offerIframeCode={messages.offerIframeCode}
            onSizeChange={onIframeSizeChange}
            selectedSize={type.iframe.query.filters.size}
            trackingLink={trackingLink}
          />
        </div>
      ) : (
        <Skeleton height="700px" width="100%" />
      ),
    },
    chatHead: {
      search: null,
      content: !creativesLoading ? (
        <CreativeAdTools
          adToolType="chathead"
          creativeCode={`<script defer src=${CHAT_HEAD_URL.concat(
            injectDefaultQueryParam({ url: trackingLink, paramName: 'aff_sub4', paramDefaultValue: ADTOOL_ID_CHATHEAD })
          )}></script>`}
          dispatch={dispatch}
          offerLandingPages={offerLandingPages}
        />
      ) : (
        <Skeleton height="700px" width="100%" />
      ),
    },
    content: {
      search: null,
      content: creativesContent,
    },
  };

  const tabIsAvailable = (tab) => {
    switch (tab) {
      case 'html':
        return creativesExist.html;
      case 'iframe':
        return creativesExist.iframe;
      case 'email':
        return showEmails;
      case 'prestitial':
        return prestitialLandingIds?.length;
      case 'postitial':
        return tabs.postitial && tabs.postitial.show;
      case 'chatHead':
        return tabs.chatHead;
      case 'link':
        return tabs.link && tabs.link.show;
      case 'trending':
        return tabs.banner && tabs.banner.show;
      case 'pop':
        return tabs.pop && tabs.pop.show;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (offerPopCode.code && trackingLink) {
      try {
        const url = new URL(trackingLink);
        url.searchParams.set('aff_sub4', 'AT_0005');
        setPopCodeTrackingLink(url.toString().replace(/%/g, ','));
      } catch (_) {
        setPopCodeTrackingLink(trackingLink);
      }
    }
  }, [offerPopCode.code, trackingLink]);

  const url = new URL(window.location.href);
  useEffect(() => {
    if (url.searchParams.get('ad-tool') && (!creativesExist.loading || creativesExist.html || creativesExist.iframe)) {
      if (tabIsAvailable(url.searchParams.get('ad-tool'))) {
        selectTab(url.searchParams.get('ad-tool'));
      }
    }
  }, [creativesExist.loading, creativesExist.html, creativesExist.iframe]);

  const minHeightTab = selectedTab === 'trending' || selectedTab === 'chatHead' ? 'initial' : adToolHeight;

  return (
    <div className={selectedTab ? `${selectedTab}Section` : ''} id="offer-creatives">
      <div>
        {tabs && Object.keys(tabs).filter((key) => tabs[key].show).length > 1 ? (
          <Scroller classNames={['tabs', 'text-center', 'small']}>
            <ul className="tabs-creatives active">
              {tabIsAvailable('link') ? (
                <>
                  {!creativesExist.loading ? (
                    <li className={getTabClassNames('link')} data-cy="creatives-tab-link" onClick={() => selectTab('link')}>
                      <FormattedMessage {...messages.offerLink} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('trending') ? (
                <>
                  {!creativesExist.loading ? (
                    <li
                      className={getTabClassNames('trending')}
                      data-cy="creatives-tab-banner"
                      onClick={() => selectTab('trending')}
                    >
                      <FormattedMessage {...messages.offerBanner} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('html') && tabs.html && tabs.html.show ? (
                <>
                  {!creativesExist.loading ? (
                    <li className={getTabClassNames('html')} data-cy="creatives-tab-html" onClick={() => selectTab('html')}>
                      <FormattedMessage {...messages.offerHtmlAds} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('pop') ? (
                <>
                  {!creativesExist.loading ? (
                    <li className={getTabClassNames('pop')} data-cy="creatives-tab-pop" onClick={() => selectTab('pop')}>
                      <FormattedMessage {...messages.offerPopCode.msgHeader} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('prestitial') && tabs.prestitial && tabs.prestitial.show ? (
                <>
                  {!creativesExist.loading ? (
                    <li
                      className={getTabClassNames('prestitial')}
                      data-cy="creatives-tab-prestitial"
                      onClick={() => selectTab('prestitial')}
                    >
                      <FormattedMessage {...messages.offerPrestitial.msgHeader} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('postitial') ? (
                <>
                  {!creativesExist.loading ? (
                    <li
                      className={getTabClassNames('postitial')}
                      data-cy="creatives-tab-postitial"
                      onClick={() => selectTab('postitial')}
                    >
                      <FormattedMessage {...messages.offerPostitial.msgHeader} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('email') && tabs.email && tabs.email.show ? (
                <>
                  {!creativesExist.loading ? (
                    mailingOfferId ? (
                      <li onClick={tabs.email.eventLabel ? Gtm.event('UI Component', 'Click', tabs.email.eventLabel) : undefined}>
                        <Link onlyActiveOnIndex={false} to={`/offers/${mailingOfferId}`}>
                          <FormattedMessage {...messages.offerEmail} />
                        </Link>
                      </li>
                    ) : (
                      <li className={getTabClassNames('email')} onClick={() => selectTab('email')}>
                        <FormattedMessage {...messages.offerEmail} />
                      </li>
                    )
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('iframe') && tabs.iframe && tabs.iframe.show ? (
                <>
                  {!creativesExist.loading ? (
                    <li className={getTabClassNames('iframe')} onClick={() => selectTab('iframe')}>
                      <FormattedMessage {...messages.offerIframe} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabIsAvailable('chatHead') ? (
                <>
                  {!creativesExist.loading ? (
                    <li className={getTabClassNames('chatHead')} onClick={() => selectTab('chatHead')}>
                      <FormattedMessage {...messages.offerChatHead} />
                    </li>
                  ) : (
                    <li>
                      <Skeleton height="20px" width="80px" />
                    </li>
                  )}
                </>
              ) : null}
              {tabs.content &&
              tabs.content.show &&
              offerVerticals &&
              offerVerticals.some((ver) => ver.id === VERTICAL_ADULTPAYSITE_ID) ? (
                <li className={getTabClassNames('content')} onClick={() => selectTab('content')}>
                  <span>Content</span>
                </li>
              ) : null}
            </ul>
          </Scroller>
        ) : null}
      </div>
      <div
        className={`adToolAreaBox ${selectedTab || ''}`}
        id="ad-tool"
        style={{
          minHeight: minHeightTab,
        }}
      >
        <div className="adToolArea" ref={adToolAreaRef}>
          {creativesExist.loading && !creativesLoading ? (
            <Skeleton height="700px" width="100%" />
          ) : (
            <>
              {content[selectedTab].search}
              {content[selectedTab].content}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Creatives.defaultProps = {
  displaySubHeaders: false,
};

Creatives.propTypes = {
  affId: PropTypes.string.isRequired,
  creativeLink: PropTypes.string,
  creatives: PropTypes.object.isRequired,
  creativesIsFetching: PropTypes.bool.isRequired,
  creativesSearchForm: PropTypes.object.isRequired,
  creativesSizes: PropTypes.array.isRequired,
  creativesSizesIsFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  displaySubHeaders: PropTypes.bool,
  iframe: PropTypes.bool,
  intl: intlShape.isRequired,
  location: PropTypes.object.isRequired,
  mailingOfferId: PropTypes.any,
  offerId: PropTypes.any.isRequired,
  offerLandingPages: PropTypes.any,
  offerOverlay: PropTypes.any,
  offerPopCode: PropTypes.any,
  offerThumbnail: PropTypes.string,
  offerVerticals: PropTypes.array,
  offersCreativesViewMode: PropTypes.string.isRequired,
  onSmartlinkCopy: PropTypes.func,
  postitialLandingIds: PropTypes.any,
  prestitialLandingIds: PropTypes.any,
  showEmailPopup: PropTypes.func,
  showEmails: PropTypes.bool,
  strippedValues: PropTypes.object.isRequired,
  tabs: PropTypes.object,
  trackingLink: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default connect((state) => ({
  creativeLink: state.offer.creativeLink,
}))(injectIntl(Creatives));
