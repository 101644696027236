import request from '../../../utils/Req';

import config from '../../../config';
import consts from '../../../utils/consts';
import { getCookie } from '../../../utils/helpers';
import { browserHistory } from 'react-router';
import { Gtm } from '../../../utils/gtm';

const { API_URL, GEODATA_URL } = config;
const {
  ACTIONS: { SSIGN_UP },
  GOOGLE_AUTH_PATH,
} = consts;

const requestValidateUser = () => ({ type: SSIGN_UP.REQUEST_VALIDATE_USER });
const receivedValidateUser = (data) => ({
  type: SSIGN_UP.RECEIVED_VALIDATE_USER,
  data,
});
const failedValidateUser = ({ message }) => ({
  type: SSIGN_UP.FAILED_VALIDATE_USER,
  err: message,
});
export const validateUser =
  ({ code, email, error, password, scope, signup }) =>
  (dispatch) => {
    console.log(code, email, error, password, scope, signup, 'code');
    dispatch(requestValidateUser());
    console.log('requested');
    return request
      .get(`${API_URL}/login/user`)
      .query({
        code,
        email,
        error,
        password,
        scope,
        signup,
        path: GOOGLE_AUTH_PATH,
      })
      .withCredentials()
      .then((res) => {
        console.log('pass');
        dispatch(receivedValidateUser(res.body));
        return res.body;
      })
      .catch(({ response }) => {
        console.log(response, 'fail');
        if (!response.body.account_status) {
          dispatch(failedValidateUser(response.body.errorMessage));
        } else {
          dispatch(receivedValidateUser(response.body));
        }
        return response.body;
      });
  };

const requestCreateLead = () => ({ type: SSIGN_UP.REQUEST_CREATE_LEAD });
const receiveCreateLead = (data) => ({
  type: SSIGN_UP.RECEIVE_CREATE_LEAD,
  data,
});
const failedToCreateLead = (err) => ({
  type: SSIGN_UP.FAILED_TO_CREATE_LEAD,
  err,
});
export const createLead = (data) => (dispatch) => {
  dispatch(requestCreateLead());
  return request
    .post(`${API_URL}/signup/create-lead`)
    .send(data)
    .set({
      'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
    })
    .withCredentials()
    .then((res) => {
      dispatch(receiveCreateLead(res.body));
      browserHistory.push({
        pathname: '/access/confirm-email',
        state: { email: data.email },
      });
      Gtm.event('Register New Form', 'Incomplete', 'Step 1');
    })
    .catch((err) => {
      let errorMessage;
      switch (err.status) {
        case 403:
        case 422:
          errorMessage = "Please confirm you're not a robot.";
          break;
        case 400:
          errorMessage =
            'You already have an account with CrakRevenue or one of its partners. Please <a href="/login">login</a> or <a href="/password-reset">reset your password</a>.';
          break;
        default:
          errorMessage = 'Unexpected error, if all your informations are valid, please contact support.';
          break;
      }
      dispatch(
        failedToCreateLead({
          status: err.status,
          message: errorMessage,
        })
      );
    });
};

const requestCreateUser = () => ({ type: SSIGN_UP.REQUEST_CREATE_USER });
const receiveCreateUser = (data) => ({
  type: SSIGN_UP.RECEIVE_CREATE_USER,
  data,
});
const failedToCreateUser = ({ message }) => ({
  type: SSIGN_UP.FAILED_TO_CREATE_USER,
  err: message,
});
export const createUser = (data) => (dispatch) => {
  dispatch(requestCreateUser());
  return request
    .post(`${API_URL}/login/user`)
    .send(data)
    .set({
      'XSRF-TOKEN': getCookie('XSRF-TOKEN'),
    })
    .withCredentials()
    .then((res) => {
      dispatch(receiveCreateUser(res.body));
      return res.body;
    })
    .catch((err) => {
      dispatch(failedToCreateUser(err.response.body.errorMessage));
      return err;
    });
};

const requestGoogleGetUrl = () => ({ type: SSIGN_UP.REQUEST_GOOGLE_GET_URL });
const receiveGoogleGetUrl = (data) => ({
  type: SSIGN_UP.RECEIVE_GOOGLE_GET_URL,
  data,
});
const failedToReceiveGoogleGetUrl = () => ({
  type: SSIGN_UP.FAILED_TO_RECEIVE_GOOGLE_GET_URL,
});
export const getGoogleUrl = () => (dispatch) => {
  dispatch(requestGoogleGetUrl());
  return new Promise((fulfill, reject) =>
    request
      .get(`${API_URL}/login/google-url`)
      .withCredentials()
      .query({ path: GOOGLE_AUTH_PATH })
      .end((err, res) => {
        if (!err) {
          dispatch(receiveGoogleGetUrl(res.body.data));
          fulfill(res);
        } else {
          dispatch(failedToReceiveGoogleGetUrl(err));
          reject(err);
        }
      })
  );
};
const requestGeoCountry = () => ({ type: SSIGN_UP.REQUEST_GEO_COUNTRY });
const failedToFetchGeoCountry = () => ({ type: SSIGN_UP.FAILED_TO_FETCH_GEO_COUNTRY });
const receiveGeoCountry = (data) => ({
  type: SSIGN_UP.RECEIVE_GEO_COUNTRY,
  data,
});
const requestHoQuestions = () => ({ type: SSIGN_UP.REQUEST_HO_QUESTIONS });
const failedToFetchHoQuestions = () => ({ type: SSIGN_UP.FAILED_TO_FETCH_HO_QUESTIONS });
const receiveHoQuestions = (data) => ({
  type: SSIGN_UP.RECEIVE_HO_QUESTIONS,
  data,
});
export const getHoQuestions = (regionOverride) => async (dispatch) => {
  dispatch(requestGeoCountry());
  let geoCountry = undefined;
  try {
    geoCountry = (await request.get(`${GEODATA_URL ?? ''}/geodata`))?.body?.country;
    dispatch(receiveGeoCountry({ geoCountry }));
  } catch (e) {
    dispatch(failedToFetchGeoCountry());
    console.log('unable to get geodata');
  }
  const country = regionOverride ?? (process.env.REACT_APP_USE_GEO_SIGNUP_FORM === 'true' ? geoCountry : undefined);
  dispatch(requestHoQuestions());
  return new Promise((fulfill, reject) =>
    request.get(`${API_URL}/signup/receive-questions${country ? `?regionOverride=${country}` : ''}`).end((err, res) => {
      if (!err) {
        dispatch(receiveHoQuestions(res.body));
        fulfill(res);
      } else {
        dispatch(failedToFetchHoQuestions());
        reject(err);
      }
    })
  );
};

export const setSignupToken = (data) => ({
  type: SSIGN_UP.SET_SIGNUP_TOKEN,
  data,
});
